import { css } from "@linaria/core";

import { MOBILE_BREAKPOINT_PX } from "../../../../styles/constants";
import { breakpointDownMixin } from "../../../../styles/mixins/breakpoints";
import { outlineMixin } from "../../../../styles/mixins/outline";
import { anchorReset, headingReset } from "../../../../styles/resets";
import { colorProps, proximaNovaProps } from "../../../../styles/variables";

export const footerHeightProp = "--footer--height";

export const footerCls = css`
  :global(:root) {
    ${footerHeightProp}: 0px;
  }

  /** pull to bottom relying on flexbox on #app selector */
  margin-top: auto;

  padding: 4.5rem 0;
  background: rgb(var(${colorProps.grey_50}));
`;

export const boundaryCls = css`
  display: flex;
  align-items: flex-start;

  @media screen and (${breakpointDownMixin(MOBILE_BREAKPOINT_PX)}) {
    flex-direction: column;
  }
`;

export const logoCls = css`
  flex-shrink: 0;
  margin-right: 5rem;

  height: 3.4rem;
`;

export const columnWrapperCls = css`
  display: flex;
  align-items: flex-start;

  width: 70rem;
  margin-left: auto;

  @media screen and (${breakpointDownMixin(MOBILE_BREAKPOINT_PX)}) {
    flex-direction: column;

    width: 100%;
    margin-top: 5.8rem;
    margin-left: 0;
  }
`;

export const columnCls = css`
  flex: 1;
  margin-right: 3rem;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (${breakpointDownMixin(MOBILE_BREAKPOINT_PX)}) {
    margin-right: 0;
    margin-bottom: 3.7rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const columnHeadingCls = css`
  ${headingReset};
  margin-bottom: 1.5rem;

  font-family: var(${proximaNovaProps.fontFamily});
  font-weight: var(${proximaNovaProps.medium});
  font-size: 1.8rem;
  line-height: 1.2;
  text-transform: uppercase;

  @media screen and (${breakpointDownMixin(MOBILE_BREAKPOINT_PX)}) {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
`;

export const columnLinkListCls = css`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const columnLinkCls = css`
  ${anchorReset};

  display: block;
  margin-top: 1.2rem;

  font-family: var(${proximaNovaProps.fontFamily});
  font-weight: var(${proximaNovaProps.regular});
  font-size: 1.4rem;
  line-height: 1.2;

  &:hover {
    text-decoration: underline;
  }

  ${outlineMixin({ targetSelector: "& > span", offset: "0.5rem" })};

  @media screen and (${breakpointDownMixin(MOBILE_BREAKPOINT_PX)}) {
    font-size: 1.6rem;
  }
`;
