import { useEffect } from "react";

type Variables = { [variableName: string]: string | number | undefined };

export function useCssVariables(
  elementRef: React.RefObject<HTMLElement>,
  variables: Variables,
): void {
  useEffect(() => {
    const element = elementRef.current;

    if (!element) {
      return;
    }

    const names = Object.keys(variables);

    for (const name of names) {
      const value = variables[String(name)];

      if (value) {
        element.style.setProperty(name, String(value));
      } else {
        element.style.removeProperty(name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef, ...Object.values(variables)]);
}
