import { WPTextBox } from "../../../../models/WPContentBlock";
import { WPProductConfig } from "../../../../models/WPProductConfig";
import { ContentBoundary } from "../../../app/layout/ContentBoundary";
import { parseHtml } from "../../../utils/dom/parseHtml";
import { CallToActionList } from "../CallToActionList/CallToActionList";

import { Hero } from "./Hero";
import * as styles from "./styles/TextBox";

type Props = {
  model: Omit<WPTextBox, "type" | "showWhenNoProductAccess">;
  product: WPProductConfig | undefined;
};

export function TextBox({ model, product }: Props): JSX.Element {
  if (model.heroImage) {
    return (
      <Hero
        model={{
          ...model,
          heroImage: model.heroImage,
        }}
        product={product}
      />
    );
  }

  return (
    <ContentBoundary>
      <h1 className={styles.headingCls}>{model.heading}</h1>
      <div className={styles.bodyCls}>{parseHtml(model.body)}</div>

      <CallToActionList buttons={model.callToActions} product={product} />
    </ContentBoundary>
  );
}
