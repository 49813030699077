import React, { useEffect, useState, useContext, createContext } from "react";
import { createRoot } from "react-dom/client";

import { ContentBoundary } from "../../app/layout/ContentBoundary";
import { throttleToAnimationFrame } from "../../utils/throttleToAnimationFrame";

const ContentBoundaryWidthContext = createContext(700);

export function useContentBoundaryWidth(): number {
  return useContext(ContentBoundaryWidthContext);
}

export function ContentBoundaryWidthProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [width, setWidth] = useState(700);

  useEffect(() => {
    const updateWidth = () => {
      determineContentBoundaryWidth().then((nextWidth) => {
        setWidth((prevWidth) => nextWidth ?? prevWidth);
      });
    };

    updateWidth();
    const handleResize = throttleToAnimationFrame(updateWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ContentBoundaryWidthContext.Provider value={width}>
      {children}
    </ContentBoundaryWidthContext.Provider>
  );
}

function determineContentBoundaryWidth(): Promise<number | undefined> {
  const calculatorNode = document.createElement("div");
  calculatorNode.style.cssText = `
    position: absolute;
    top: -999rem;
    left: -999rem;
    width: 100%;
  `;

  return new Promise((resolve) => {
    const root = createRoot(calculatorNode);

    const handleCleanup = () => {
      setImmediate(() => {
        document.body.appendChild(calculatorNode);

        resolve(
          calculatorNode.querySelector<HTMLElement>(
            `[data-selector="width-calculator"]`,
          )?.offsetWidth,
        );

        root.unmount();
        document.body.removeChild(calculatorNode);
      });
    };

    root.render(
      <ContentBoundary ref={handleCleanup}>
        <div data-selector="width-calculator" />
      </ContentBoundary>,
    );
  });
}
