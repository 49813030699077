import { useRef, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { GyldendalLogoSmall } from "../../../components/icons/PublisherLogo";
import { useUser } from "../../../hooks/context/useUser";
import { AppPage } from "../../hooks/usePageFromRouter";

import { LoginButton } from "./LoginButton";
import { MobileNavigation } from "./Navigation/MobileNavigation";
import { Navigation } from "./Navigation/Navigation";
import { useNavigation } from "./Navigation/hooks/useNavigation";
import { SubHeader } from "./SubHeader";
import { UserButton } from "./UserButton";
import * as styles from "./styles/Header";

type Props = {
  page: AppPage | undefined;
};

export function Header({ page }: Props): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const user = useUser();
  const navigate = useNavigate();
  const navigation = useNavigation(user);
  const withMobileMenu = (navigation?.length ?? 0) > 0;

  useHeaderHeightCssProp(ref);

  return (
    <CSSTransition
      in={!!navigation && !!page}
      classNames={styles.headerCls}
      timeout={styles.headerSlideDurationMs}
    >
      <header ref={ref} className={styles.headerCls}>
        <div className={styles.containerCls}>
          <span
            className={styles.logoWrapperCls}
            onClick={() => {
              navigate("/");
            }}
          >
            <GyldendalLogoSmall className={styles.logoCls} />
            <h1 className={styles.headingCls}>Gyldendals webprøver</h1>
          </span>

          <div className={styles.rightSectionCls}>
            <Navigation navigation={navigation} />

            {user ? (
              <UserButton
                className={styles.userButtonCls}
                headerRef={ref}
                user={user}
              />
            ) : (
              <LoginButton className={styles.userButtonCls} headerRef={ref} />
            )}
          </div>
        </div>

        {withMobileMenu && (
          <MobileNavigation page={page} navigation={navigation} />
        )}

        <SubHeader page={page} />
      </header>
    </CSSTransition>
  );
}

function useHeaderHeightCssProp(ref: React.RefObject<HTMLDivElement>): void {
  useLayoutEffect(() => {
    const updateHeightProp = () => {
      document.documentElement.style.setProperty(
        styles.headerHeightProp,
        `${ref.current?.offsetHeight ?? 0}px`,
      );
    };

    updateHeightProp();

    if (!ref.current) {
      return;
    }

    const observer = new ResizeObserver(updateHeightProp);

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref]);
}
