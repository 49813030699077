import { makeFetchConfiguration } from "./fetchConfiguration";
import { makeFetchFooter } from "./fetchFooter";
import { makeFetchNavigation } from "./fetchNavigation";
import { makeFetchPage } from "./fetchPage";
import { makeFetchPracticeTests } from "./fetchPracticeTests";
import { makeFetchProductNavigation } from "./fetchProductNavigation";
import { makeFetchProducts } from "./fetchProducts";
import { makeFetchStudentActiveTests } from "./fetchStudentActiveTests";
import { makeFetchStudentCompletedTests } from "./fetchStudentCompletedTests";
import { makeFetchTeacherAvailableTests } from "./fetchTeacherAvailableTests";
import { makeFetchUser } from "./fetchUser";
import { makeIsAuthorized } from "./isAuthorized";
import { ApiClientOptions } from "./typings/ApiClientOptions";
import { ApiRequestInterceptor } from "./typings/ApiRequestInterceptor";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function makeApiClient(
  requestInterceptor: ApiRequestInterceptor,
  options: ApiClientOptions = { disableCache: false },
) {
  const fetchTeacherAvailableTests = makeFetchTeacherAvailableTests(
    requestInterceptor,
    options,
  );

  const fetchPracticeTests = makeFetchPracticeTests(
    requestInterceptor,
    options,
  );

  return Object.freeze({
    fetchUser: makeFetchUser(requestInterceptor, options),
    fetchConfiguration: makeFetchConfiguration(requestInterceptor, options),
    fetchFooter: makeFetchFooter(requestInterceptor, options),
    fetchNavigation: makeFetchNavigation(requestInterceptor, options),
    fetchProducts: makeFetchProducts(requestInterceptor, options),
    fetchProductNavigation: makeFetchProductNavigation(
      requestInterceptor,
      options,
      {
        fetchPracticeTests,
        fetchTeacherAvailableTests,
      },
    ),
    fetchPage: makeFetchPage(requestInterceptor, options),
    fetchStudentActiveTests: makeFetchStudentActiveTests(
      requestInterceptor,
      options,
    ),
    fetchStudentCompletedTests: makeFetchStudentCompletedTests(
      requestInterceptor,
      options,
    ),
    fetchTeacherAvailableTests,
    fetchPracticeTests,
    isAuthorized: makeIsAuthorized(requestInterceptor, options),
  });
}

export type ApiClient = ReturnType<typeof makeApiClient>;
