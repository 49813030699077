import nProgress from "nprogress";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import {
  WPAsyncPageGroupDescriptor,
  WPPageGroupDescriptor,
} from "../../../../models/WPPageDescriptor";
import { isAsyncPageGroupDescriptor } from "../../../../utils/guards/isAsyncPageGroupDescriptor";
import { isPageGroupDescriptor } from "../../../../utils/guards/isPageGroupDescriptor";
import { HeightTransition } from "../../../components/transitions/HeightTransition";
import { useLoaderState } from "../../../hooks/state/useLoaderState";
import { useActivePagePath } from "../../hooks/useActivePagePath";

import { LinkItem } from "./LinkItem";
import { MobileLinkItem } from "./MobileLinkItem";
import * as styles from "./styles/Group";
import { itemCls } from "./styles/LinkList";

export function LinkGroup({
  item,
  forceExpanded,
  Link,
  onLinkClick,
}: {
  item:
    | WPPageGroupDescriptor
    | WPAsyncPageGroupDescriptor
    | WPAsyncPageGroupDescriptor;
  forceExpanded?: boolean;

  Link: typeof LinkItem | typeof MobileLinkItem;
  onLinkClick?(
    evt: React.MouseEvent<HTMLAnchorElement>,
    isGroupLink?: boolean,
  ): void;
}): JSX.Element | null {
  const navigate = useNavigate();
  const activePagePath = useActivePagePath();
  const pathExpanded = activePagePath.startsWith(item.path);

  const [pages, setPages] = useState(() => {
    if (isPageGroupDescriptor(item)) {
      return item.pages;
    }

    if (isAsyncPageGroupDescriptor(item)) {
      if (forceExpanded || pathExpanded) {
        const pages = item.getPages();

        // instantly provide list of pages, if it's already available within the
        // client cache
        if (!(pages instanceof Promise)) {
          return pages;
        }
      }
    }
  });

  const isActive =
    pages && (forceExpanded ?? activePagePath.startsWith(item.path));
  const showSpinner = useLoaderState(!!forceExpanded && !pages, {
    delayMs: 125,
    durationMs: 600,
  });

  const handleClickOnGroup = useCallback(
    (evt: React.MouseEvent<HTMLAnchorElement>) => {
      onLinkClick?.(evt, true);

      if (isAsyncPageGroupDescriptor(item) && !onLinkClick) {
        // don't try navigating straight to async page groups on desktop,
        // instead wait until we've fetched the postloaded set of pages, and
        // then navigate straight there
        evt.preventDefault();

        if (isActive) {
          return;
        }

        // show throbber while waiting for pages to become available
        const nProgressTimeoutId = setTimeout(() => {
          nProgress.start();
        }, 125);

        Promise.resolve(item.getPages()).then((pages) => {
          if (pages[0]) {
            navigate(pages[0].path);
          }

          clearTimeout(nProgressTimeoutId);
          nProgress.done();
        });
      }
    },
    [onLinkClick, navigate, item, isActive],
  );

  // auto-fetch links whenever the path becomes activated for one reason or
  // another
  useEffect(() => {
    if (!isAsyncPageGroupDescriptor(item)) {
      return;
    }

    if (!forceExpanded && !pathExpanded) {
      return;
    }

    if (pages) {
      return;
    }

    Promise.resolve(item.getPages()).then((pages) => setPages(pages));
  }, [item, forceExpanded, pathExpanded, pages]);

  if (isPageGroupDescriptor(item) && !item.pages?.[0]) {
    return null;
  }

  return (
    <>
      <Link
        item={item}
        disabled={isActive}
        onClick={handleClickOnGroup}
        spin={showSpinner.current}
      />

      <HeightTransition
        in={isActive}
        timeout={styles.subGroupTransitionDurationMs}
        mountOnEnter
        unmountOnExit
      >
        <ul className={styles.subGroupCls}>
          {pages?.map((it) => (
            <li key={it.path} className={itemCls}>
              <Link item={it} onClick={onLinkClick} />
            </li>
          ))}
        </ul>
      </HeightTransition>
    </>
  );
}
