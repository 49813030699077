import { WPAccordionCollection } from "../../../../models/WPContentBlock";
import { WPProductConfig } from "../../../../models/WPProductConfig";

export async function fetchAccordionCollection(
  model: WPAccordionCollection,
  product: WPProductConfig | undefined,
  isAuthorized: boolean,
): Promise<JSX.Element> {
  const { AccordionCollection } = await import(
    "../../../components/blocks/AccordionCollection/AccordionCollection"
  );

  return (
    <AccordionCollection
      model={model}
      product={product}
      isAuthorized={isAuthorized}
    />
  );
}
