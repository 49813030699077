import { css } from "@linaria/core";

import { TABLET_BREAKPOINT_PX } from "../../../../styles/constants";
import { breakpointUpMixin } from "../../../../styles/mixins/breakpoints";
import { colorPropMixin } from "../../../../styles/mixins/colorProp";
import { fontMixin } from "../../../../styles/mixins/fontMixin";
import { outlineMixin } from "../../../../styles/mixins/outline";
import { buttonReset } from "../../../../styles/resets";
import {
  colorProps,
  headerPaddingProp,
  proximaNovaProps,
} from "../../../../styles/variables";
import { fadeInTransitionDurationMs } from "../../../styles/App";

export const subHeaderHeightProp = "--sub-header--height";
export const subHeaderHeightTransitionDurationMs = 200;

export const subHeaderCls = css`
  :global(:root) {
    ${subHeaderHeightProp}: 6rem;
  }

  position: relative;
  height: 6rem;

  /**
   * add enter / exit transitions
   */
  &.enter {
    opacity: 0.001;

    &-active {
      opacity: 1;
      transition: opacity ${fadeInTransitionDurationMs}ms linear;
    }
  }

  &.exit {
    display: none;
  }

  /**
   * hide regular "parent page" subheader on desktop based devices; menu is
   * available within sub navigation and there is no product branding to be
   * added
   */
  @media screen and (${breakpointUpMixin(TABLET_BREAKPOINT_PX)}) {
    &:not(.withProductStyling) {
      display: none;
    }
  }
`;

export const buttonCls = css`
  ${buttonReset};
  ${outlineMixin()};

  height: 100%;
  width: 100%;
`;

export const containerCls = css`
  position: absolute;
  inset: 0;

  display: flex;
  align-items: center;

  background: ${colorPropMixin(colorProps.grey_50)};
  color: ${colorPropMixin(colorProps.text)};

  ${fontMixin(proximaNovaProps, {
    weight: "medium",
    size: "1.8rem",
  })}

  &.withProductStyling {
    background: ${colorPropMixin(colorProps.brand)};
    color: ${colorPropMixin(colorProps.textOnBrand)};
  }

  /**
   * add enter / exit transitions
   */
  &.enter {
    opacity: 0.001;
    z-index: 1;

    &-active {
      opacity: 1;
      transition: opacity ${fadeInTransitionDurationMs * 0.5}ms linear;
    }
  }
`;

export const contentCls = css`
  display: flex;

  align-items: center;
  justify-content: space-between;

  padding: 0 var(${headerPaddingProp});
`;

export const headingCls = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const chevronCls = css`
  margin-left: 3rem;

  @media screen and (${breakpointUpMixin(TABLET_BREAKPOINT_PX)}) {
    display: none;
  }
`;
