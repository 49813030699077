import { Link } from "react-router-dom";

import { WPNavigationLink } from "../../../../../models/WPNavigationLink";
import {
  WPPageDescriptor,
  WPPageGroupDescriptor,
} from "../../../../../models/WPPageDescriptor";
import { isNavigationLink } from "../../../../../utils/guards/isNavigationLink";
import { isPageDescriptor } from "../../../../../utils/guards/isPageDescriptor";
import { isPageGroupDescriptor } from "../../../../../utils/guards/isPageGroupDescriptor";
import { ExternalLinkIcon } from "../../../../components/icons/ExternalLinkIcon";

import * as styles from "./styles/NavigationLink";

type Props = {
  className: string;
  activePath: string;

  model: WPNavigationLink | WPPageDescriptor | WPPageGroupDescriptor;

  onClick?(): void;
};

export function NavigationLink({
  activePath,
  className,
  model,
  onClick,
}: Props): JSX.Element | null {
  if (isNavigationLink(model)) {
    return (
      <a
        className={className}
        href={model.url}
        rel="noopener noreferrer"
        target="_blank"
        onClick={onClick}
      >
        {model.title}
        <ExternalLinkIcon className={styles.externalLinkIcon} />
      </a>
    );
  }

  if (isPageGroupDescriptor(model)) {
    if (!model.pages?.[0]) {
      return null;
    }

    return (
      <Link
        to={model.pages[0].path}
        className={className}
        aria-current={
          activePath.startsWith(`${model.path}/`) ? "location" : undefined
        }
        onClick={onClick}
      >
        {model.title}
      </Link>
    );
  }

  if (isPageDescriptor(model)) {
    return (
      <Link
        to={model.path}
        className={className}
        aria-current={activePath === model.path ? "page" : undefined}
        onClick={onClick}
      >
        {model.title}
      </Link>
    );
  }

  return null;
}
