import { cx } from "@linaria/core";
import React from "react";
import { Link } from "react-router-dom";

import {
  WPAsyncPageGroupDescriptor,
  WPPageDescriptor,
  WPPageGroupDescriptor,
} from "../../../../models/WPPageDescriptor";
import { isPageDescriptor } from "../../../../utils/guards/isPageDescriptor";
import { isPageGroupDescriptor } from "../../../../utils/guards/isPageGroupDescriptor";
import { useActivePagePath } from "../../hooks/useActivePagePath";

import * as styles from "./styles/Link";

export function LinkItem({
  item,
  disabled,
  onClick,
}: {
  item: WPPageDescriptor | WPPageGroupDescriptor | WPAsyncPageGroupDescriptor;
  disabled?: boolean;

  onClick?(evt: React.MouseEvent<HTMLAnchorElement>): void;
}): JSX.Element {
  const activePagePath = useActivePagePath();

  return (
    <Link
      to={
        !isPageGroupDescriptor(item) ? item.path : item.pages?.[0]?.path ?? ""
      }
      className={cx(styles.linkCls, disabled && "disabled")}
      aria-current={
        isPageDescriptor(item) && item.path === activePagePath
          ? "page"
          : undefined
      }
      onClick={onClick}
    >
      <span data-wp-outline>{item.title}</span>
    </Link>
  );
}
