import { User } from "../../models/User";
import { WPProductConfig } from "../../models/WPProductConfig";
import { WPTestGroup } from "../../models/WPTestGroup";
import { DAYS_TO_MS, HOURS_TO_MS } from "../constants/time";
import { createCache } from "../createCache";

import { ApiClientOptions } from "./typings/ApiClientOptions";
import { ApiRequestInterceptor } from "./typings/ApiRequestInterceptor";
import { fetchRequest } from "./utils/fetchRequest";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function makeFetchPracticeTests(
  requestInterceptor: ApiRequestInterceptor,
  options: ApiClientOptions,
) {
  const cache = createCache<WPTestGroup[]>({
    timeToLiveMs: 1 * HOURS_TO_MS,
    timeToEvictMs: 1 * DAYS_TO_MS,
    ...options,
  });

  return (user: User, product: WPProductConfig) => {
    return cache(`i:${user.institutionId}-p:${product.id}`, async () => {
      const response = await fetchRequest<WPTestGroup>(
        requestInterceptor(
          `${process.env.WP_PORTAL_API}/products/${product.id}/tests/${user.institutionId}`,
        ),
      );

      return response.produktKategorier ?? [];
    });
  };
}
