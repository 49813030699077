import { cx } from "@linaria/core";
import React from "react";
import { Link } from "react-router-dom";

import {
  WPCallToAction,
  WPCallToActionTypes,
} from "../../../../models/WPCallToAction";
import { WPProductConfig } from "../../../../models/WPProductConfig";
import { warnOnce } from "../../../../utils/logs/warnOnce";
import { getLoginUrl } from "../../../utils/api/loginConnector";

import * as styles from "./styles/CallToActionButton";

type Props = {
  model: WPCallToAction;
  product: WPProductConfig | undefined;
  primary?: boolean;
};

export function CallToActionButton({
  model,
  product,
  primary,
}: Props): JSX.Element {
  const linkUrl = useLinkFromModel(model, product);

  return !linkUrl.includes("//") ? (
    <Link
      className={cx(styles.buttonCls, primary && styles.primaryCls)}
      to={linkUrl}
      onClick={getActionFromModel(model)}
    >
      {model.text}
    </Link>
  ) : (
    <a
      className={cx(styles.buttonCls, primary && styles.primaryCls)}
      href={linkUrl}
      target={model.type === WPCallToActionTypes.Login ? "_self" : "_blank"}
      rel="noreferrer noopener"
    >
      {model.text}
    </a>
  );
}

function useLinkFromModel(
  model: WPCallToAction,
  product: WPProductConfig | undefined,
): string {
  switch (model.type) {
    case WPCallToActionTypes.GoToFrontPage:
      return "/";

    case WPCallToActionTypes.Login:
      return getLoginUrl();

    case WPCallToActionTypes.GetTrialAccess:
      return (
        product?.trialUrl || "https://gyldendal-uddannelse.dk/soeg?q=webprøver"
      );

    case WPCallToActionTypes.ExternalLink:
    case WPCallToActionTypes.InternalLink:
      return model.linkUrl;

    case WPCallToActionTypes.ReloadPage:
      return window.location.pathname;
  }

  warnOnce(
    "<CallToActionButton />.getLinkFromModel(): unable to determine link from type '%s'",
    // @ts-expect-error: exists just in case we have an untyped call to action
    model.type,
  );

  return "/";
}

function getActionFromModel(
  model: WPCallToAction,
): ((evt: React.MouseEvent<HTMLAnchorElement>) => void) | undefined {
  switch (model.type) {
    case WPCallToActionTypes.ReloadPage:
      return (evt) => {
        evt.preventDefault();
        window.location.reload();
      };
  }
}
