import { createContext, useContext } from "react";

import { AppPage } from "./usePageFromRouter";

const ActivePageContext = createContext<AppPage | undefined>(undefined);

export function useActivePage(): AppPage | undefined {
  return useContext(ActivePageContext);
}

export const ActivePageProvider = ActivePageContext.Provider;
