import { useRef, useState, useEffect } from "react";

import { isTouchDevice } from "../../../../utils/dom/isTouchDevice";

const HOVER_DELAY_MS = 400;

let hasJustShowedTooltip = false;
let hasJustShowedTooltipTimeout: number | undefined;

/**
 * track user interactions with the DOM elements, and determine when to activate
 * the tooltip.
 */
export function useActiveState({
  togglerRef,
  containerRef,
  disabled,
  enabled,
  showAfterTapMs,
}: {
  togglerRef?: React.RefObject<HTMLElement | SVGElement>;
  containerRef: React.RefObject<HTMLElement | SVGElement>;
  disabled?: boolean;
  enabled?: boolean;
  showAfterTapMs?: number;
}): boolean {
  const ref = togglerRef ?? containerRef;

  const isHovered = useHoverState(ref);
  const isTouched = useTouchedState(ref);
  const isFocused = useFocusedState(ref);
  const wasJustTapped = useTappedState(ref, showAfterTapMs);

  const active =
    !disabled &&
    (enabled || isHovered || isTouched || isFocused || wasJustTapped);

  // register when we just activated a tooltip (used to allow instant activation
  // of other tooltips when using hover)
  useEffect(() => {
    window.clearTimeout(hasJustShowedTooltipTimeout);

    if (active) {
      hasJustShowedTooltip = true;
    } else {
      hasJustShowedTooltipTimeout = window.setTimeout(() => {
        hasJustShowedTooltip = false;
      }, HOVER_DELAY_MS);
    }
  }, [active]);

  return active;
}

/**
 * determine whether the user is hovering the tooltip for so long that it should
 * be activated.
 */
function useHoverState(
  ref: React.RefObject<HTMLElement | SVGElement>,
): boolean {
  const [isHovered, setHovered] = useState(false);
  const timeoutRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    const container = ref.current;

    if (container === null) {
      return;
    }

    // register hover after the defined timeout when mouse enters the layer
    const handlePointerOver = (): void => {
      window.clearTimeout(timeoutRef.current);

      timeoutRef.current = window.setTimeout(
        () => {
          setHovered(true);
        },

        // allow showing tooltip instantly on hover, if we've just displayed
        // another one (this allows user to easily browse tooltips that are
        // connected to grouped elements, for instance a collection of tools)
        !hasJustShowedTooltip ? HOVER_DELAY_MS : 0,
      );
    };

    // instantly reset hover once the mouse leaves again
    const handlePointerLeave = (): void => {
      window.clearTimeout(timeoutRef.current);
      setHovered(false);
    };

    container.addEventListener("pointerenter", handlePointerOver);
    container.addEventListener("pointerleave", handlePointerLeave);

    return (): void => {
      container.removeEventListener("pointerenter", handlePointerOver);
      container.removeEventListener("pointerleave", handlePointerLeave);
    };
  }, [ref]);

  return isHovered;
}

/**
 * determine whether the user has been touching the element for long enough to
 * activate the tooltip.
 */
function useTouchedState(
  ref: React.RefObject<HTMLElement | SVGElement>,
): boolean {
  const [isTouched, setTouched] = useState(false);
  const timeoutRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    const container = ref.current;

    if (container === null || !isTouchDevice()) {
      // don't even attempt to use touch detection if the device isn't touch
      // based
      return;
    }

    // register touch after the user has held the element for long enough to
    // activate it
    const handleTouchStart = (): void => {
      window.clearTimeout(timeoutRef.current);

      timeoutRef.current = window.setTimeout(() => {
        setTouched(true);
      }, HOVER_DELAY_MS);
    };

    // instantly reset hover once the mouse leaves again
    const handleTouchEnd = (): void => {
      window.clearTimeout(timeoutRef.current);
      setTouched(false);
    };

    container.addEventListener("pointerdown", handleTouchStart);
    container.addEventListener("pointerup", handleTouchEnd);
    container.addEventListener("pointercancel", handleTouchEnd);

    return (): void => {
      container.removeEventListener("pointerdown", handleTouchStart);
      container.removeEventListener("pointerup", handleTouchEnd);
      container.removeEventListener("pointercancel", handleTouchEnd);
    };
  }, [ref]);

  return isTouched;
}

/**
 * determine whether to activate the tooltip because the user has just moved
 * focus to the container.
 */
function useFocusedState(
  ref: React.RefObject<HTMLElement | SVGElement>,
): boolean {
  const [isFocused, setFocused] = useState(false);

  useEffect(() => {
    const container = ref.current;

    if (container === null) {
      return;
    }

    // register when user interacts with the UI using a pointer (used inside
    // handleFocus)
    let hasJustUsedPointer = false;
    let hasJustUsedPointerTimeout: number | undefined;

    const handlePointerDown = (): void => {
      hasJustUsedPointer = true;

      window.clearTimeout(hasJustUsedPointerTimeout);
      hasJustUsedPointerTimeout = window.setTimeout(() => {
        hasJustUsedPointer = true;
      }, 50);
    };

    // register when user activates a keyboard shortcut (used inside
    // handleFocus)
    let hasJustUsedShortcut = false;
    let hasJustUsedShortcutTimeout: number | undefined;

    const handleKeyDown = (evt: KeyboardEvent): void => {
      // assumption (1): shortcuts are always applied using a modifier key
      if (!evt.ctrlKey && !evt.shiftKey && !evt.altKey && !evt.metaKey) {
        return;
      }

      // assumption (2): shortcuts are always applied using an alphanumeric key
      // for easy recognition and cross OS support
      if (evt.key.match(/^[a-z0-9]$/i) === null) {
        return;
      }

      hasJustUsedShortcut = true;

      window.clearTimeout(hasJustUsedShortcutTimeout);
      hasJustUsedShortcutTimeout = window.setTimeout(() => {
        hasJustUsedShortcut = false;
      }, 50);
    };

    // only handle the focus event if the user hasn't just activated a shortcut
    // and/or interacted with the ui using his pointer (in which case we assume
    // he already knew the purpose of the item)
    const handleFocus = (): void => {
      if (!hasJustUsedPointer || !hasJustUsedShortcut) {
        return;
      }

      setFocused(true);
    };

    // always allow deactivating the tooltip when the user moves focus away
    // from the element again
    const handleBlur = (): void => {
      setFocused(false);
    };

    container.addEventListener("focus", handleFocus);
    container.addEventListener("blur", handleBlur);
    window.addEventListener("pointerdown", handlePointerDown, {
      capture: true,
    });
    window.addEventListener("keydown", handleKeyDown, {
      capture: true,
    });

    return (): void => {
      container.removeEventListener("focus", handleFocus);
      container.removeEventListener("blur", handleBlur);
      window.removeEventListener("pointerdown", handlePointerDown, {
        capture: true,
      });
      window.removeEventListener("keydown", handleKeyDown, {
        capture: true,
      });
    };
  }, [ref]);

  return isFocused;
}

/**
 * determine if the container was just tapped on a touch-based device and retain
 * the tooltip for the given amount of time afterwards
 */
function useTappedState(
  ref: React.RefObject<HTMLElement | SVGElement>,
  showAfterTapMs: number | undefined,
): boolean {
  const [wasJustTapped, setJustTapped] = useState(false);

  useEffect(() => {
    let closeTimeout: number | undefined;
    const container = ref.current;

    if (container === null || showAfterTapMs === undefined) {
      return;
    }

    const handleContainerTap = () => {
      window.clearTimeout(closeTimeout);

      // register that container was tapped for the given timeout
      setJustTapped(true);
      closeTimeout = window.setTimeout(() => {
        setJustTapped(false);
        window.removeEventListener("touchstart", handleTapOutside);
      }, showAfterTapMs);

      // bind listener to auto-collapse the tooltip when the user clicks
      // outside the container
      window.addEventListener("touchstart", handleTapOutside);
    };

    const handleTapOutside = (evt: TouchEvent) => {
      if (isEventFiredFromContainer(evt, container)) {
        return;
      }

      // auto-reset state when user taps outside the container element again
      setJustTapped(false);

      // clean up timeouts and event listeners that are no longer needed
      window.clearTimeout(closeTimeout);
      window.removeEventListener("touchstart", handleTapOutside);
    };

    container.addEventListener("touchstart", handleContainerTap);

    return () => {
      container.removeEventListener("touchstart", handleContainerTap);
    };
  }, [ref, showAfterTapMs]);

  return wasJustTapped;
}

function isEventFiredFromContainer(evt: Event, container: Node): boolean {
  return evt.target === container || container.contains(evt.target as Node);
}
