import { WPNavigationLink } from "../../models/WPNavigationLink";
import {
  WPAsyncPageGroupDescriptor,
  WPPageDescriptor,
  WPPageGroupDescriptor,
} from "../../models/WPPageDescriptor";

export function isPageDescriptor(
  descriptor:
    | WPNavigationLink
    | WPPageGroupDescriptor
    | WPAsyncPageGroupDescriptor
    | WPPageDescriptor,
): descriptor is WPPageDescriptor {
  return (
    "path" in descriptor &&
    (!("pages" in descriptor) || !descriptor.pages) &&
    !("getPages" in descriptor)
  );
}
