import { WPCallToActionTypes } from "../../../../models/WPCallToAction";
import { TextBox } from "../../../components/blocks/TextBox/TextBox";

const SUPPORT_TICKET_LINK =
  "https://gyldendal-uddannelse.dk/kundeservice/teknisk-support";

export function ErrorBoundaryMessage(): JSX.Element {
  return (
    <TextBox
      model={{
        heading: "Noget gik galt",
        body: `
          <p>
            Der opstod desværre en fejl, da vi forsøgte at hente siden.
          </p>
          <p>
            Vi anbefaler du forsøger at genindlæse siden.<br />
            Hvis fejlen fortsat opstår, kan du oprette en supportsag, så vil vi hurtigst muligt løse problemet.
          </p>
        `,
        callToActions: [
          {
            type: WPCallToActionTypes.ReloadPage,
            text: "Genindlæs siden",
          },
          {
            type: WPCallToActionTypes.ExternalLink,
            text: "Opret supportsag",
            linkUrl: SUPPORT_TICKET_LINK,
          },
        ],
      }}
      product={undefined}
    />
  );
}
