import { cx } from "@linaria/core";
import React from "react";

import * as styles from "./styles/LayoutBoundary";

export function LayoutBoundary({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}): JSX.Element {
  return <div className={cx(styles.boundaryCls, className)}>{children}</div>;
}
