import { WPResourceCollection } from "../../../../models/WPContentBlock";

export async function fetchResourceCollection(
  model: WPResourceCollection,
): Promise<JSX.Element> {
  const { ResourceCollection } = await import(
    "../../../components/blocks/ResourceCollection/ResourceCollection"
  );

  return <ResourceCollection model={model} />;
}
