import { WPProductBanner } from "../../../../models/WPContentBlock";

export async function fetchProductBanner(
  model: WPProductBanner,
): Promise<JSX.Element> {
  const { ProductBanner } = await import(
    "../../../components/blocks/ProductBanner/ProductBanner"
  );

  return <ProductBanner model={model} />;
}
