import { User, UserTypes } from "../../../../models/User";
import { WPApiBlock } from "../../../../models/WPContentBlock";
import { ApiClient } from "../../../../utils/api/_makeApiClient";

export async function fetchAvailableProducts(
  apiClient: ApiClient,
  user: User | undefined,
  block: WPApiBlock,
): Promise<JSX.Element> {
  const { products } = window._wp.ssr;

  const [authorizations, { ProductList }] = await Promise.all([
    apiClient.isAuthorized(
      user,
      products.map((it) => it.isbn),
    ),
    import("../../../components/blocks/ProductList/ProductList"),
  ]);

  const availableProducts = products.filter(
    (it) => authorizations[String(it.isbn)],
  );

  return (
    <ProductList
      model={block}
      products={availableProducts}
      withHardcodedTestLink={user?.type === UserTypes.STUDENT}
    />
  );
}
