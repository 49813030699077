import { WPTextBox } from "../../../../models/WPContentBlock";
import { WPProductConfig } from "../../../../models/WPProductConfig";
import { LayoutBoundary } from "../../../app/layout/LayoutBoundary";
import { parseHtml } from "../../../utils/dom/parseHtml";
import { CallToActionList } from "../CallToActionList/CallToActionList";

import * as styles from "./styles/Hero";

type Props = {
  model: Omit<WPTextBox, "type" | "showWhenNoProductAccess"> & {
    heroImage: string;
  };
  product: WPProductConfig | undefined;
};

export function Hero({ model, product }: Props): JSX.Element {
  return (
    <div className={styles.wrapperCls}>
      <LayoutBoundary className={styles.boundaryCls}>
        <h1 className={styles.headingCls}>{model.heading}</h1>
        <div className={styles.bodyCls}>{parseHtml(model.body)}</div>

        <img
          className={styles.imageCls}
          src={`${process.env.WP_MEDIA_PATH}${model.heroImage}`}
          alt=""
          role="presentation"
        />

        <CallToActionList buttons={model.callToActions} product={product} />
      </LayoutBoundary>
    </div>
  );
}
