import { css } from "@linaria/core";

import { colorPropMixin } from "../../../../styles/mixins/colorProp";
import { fontMixin } from "../../../../styles/mixins/fontMixin";
import { outlineMixin } from "../../../../styles/mixins/outline";
import { anchorReset } from "../../../../styles/resets";
import { colorProps, proximaNovaProps } from "../../../../styles/variables";

export const spinnerFadeDurationMs = 100;

export const linkCls = css`
  ${anchorReset};

  --height: 4rem;
  --line-height: 2.4rem;

  /** setup spacing and sizing of underline indicator */
  display: block;
  position: relative;
  padding: calc((var(--height) - var(--line-height)) / 2) 0;
  padding-right: 5rem;

  ${fontMixin(proximaNovaProps, {
    weight: "regular",
    size: "1.6rem",
  })}
  line-height: var(--line-height);

  /** interaction styling */
  ${outlineMixin({
    targetSelector: "[data-wp-outline]",
    offset: { x: "1rem", y: "0.6rem" },
  })};

  &[aria-current="page"] {
    font-weight: var(${proximaNovaProps.medium});
  }

  &:hover {
    text-decoration: underline;
  }

  &[aria-current="page"],
  &.disabled {
    cursor: default;

    &::before {
      display: none;
    }
  }

  & [data-wp-outline] {
    display: inline-block;
  }
`;

export const spinnerCls = css`
  position: absolute;
  top: 1rem;
  right: 1.5rem;

  box-sizing: border-box;
  width: 2rem;
  height: 2rem;

  border: 2px solid ${colorPropMixin(colorProps.textOnBrand, 0.25)};
  border-top-color: ${colorPropMixin(colorProps.textOnBrand)};
  border-radius: 50%;

  animation: spin 1s linear infinite;

  &.enter {
    opacity: 0.001;

    &-active {
      opacity: 1;
      transition: opacity ${spinnerFadeDurationMs}ms linear;
    }
  }

  &.exit {
    opacity: 1;

    &-active {
      opacity: 0.001;
      transition: opacity ${spinnerFadeDurationMs}ms linear;
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;
