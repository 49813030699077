import { WPCallToAction } from "../../../../models/WPCallToAction";
import { WPProductConfig } from "../../../../models/WPProductConfig";

import { CallToActionButton } from "./CallToActionButton";
import * as styles from "./styles/CallToActionList";

type Props = {
  product: WPProductConfig | undefined;
  buttons: WPCallToAction[] | undefined;
};

export function CallToActionList({
  product,
  buttons,
}: Props): JSX.Element | null {
  if (!buttons?.length) {
    return null;
  }

  return (
    <ul className={styles.listCls}>
      {buttons.map((button, index) => (
        <li key={index} className={styles.itemCls}>
          <CallToActionButton
            model={button}
            product={product}
            primary={index === 0}
          />
        </li>
      ))}
    </ul>
  );
}
