import { WPNavigationLink } from "../../models/WPNavigationLink";
import {
  WPAsyncPageGroupDescriptor,
  WPPageDescriptor,
  WPPageGroupDescriptor,
} from "../../models/WPPageDescriptor";

export function isPageGroupDescriptor(
  descriptor:
    | WPNavigationLink
    | WPPageGroupDescriptor
    | WPAsyncPageGroupDescriptor
    | WPPageDescriptor,
): descriptor is WPPageGroupDescriptor {
  return !!("pages" in descriptor && descriptor.pages);
}
