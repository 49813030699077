import { WPNavigationLink } from "../../models/WPNavigationLink";
import {
  WPAsyncPageGroupDescriptor,
  WPPageDescriptor,
  WPPageGroupDescriptor,
} from "../../models/WPPageDescriptor";

export function isNavigationLink(
  descriptor:
    | WPNavigationLink
    | WPPageGroupDescriptor
    | WPAsyncPageGroupDescriptor
    | WPPageDescriptor,
): descriptor is WPNavigationLink {
  return "url" in descriptor;
}
