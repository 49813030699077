import { css } from "@linaria/core";

import { verticalMarginMixin } from "../../../styles/mixins/collapsableMargin";
import { boundaryPaddingProp } from "../../../styles/variables";

export const boundaryCls = css`
  --desired-width: 69rem;

  box-sizing: border-box;
  width: calc(var(--desired-width) + var(${boundaryPaddingProp}) * 2);
  max-width: 100%;

  padding: 0 var(${boundaryPaddingProp});
  margin: 0 auto;
  ${verticalMarginMixin("3rem")};
`;
