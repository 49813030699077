import { cx } from "@linaria/core";
import { useRef, useEffect } from "react";
import { CSSTransition } from "react-transition-group";

import { User } from "../../../../models/User";
import { LogoutIcon } from "../../../components/icons/LogoutIcon";
import { UserIcon } from "../../../components/icons/UserIcon";
import { Tooltip } from "../../../components/poppers/Tooltip/Tooltip";
import { useOverlay } from "../../../hooks/effect/useOverlay";
import { getLogoutUrl } from "../../../utils/api/loginConnector";

import * as styles from "./styles/UserButton";

type Props = {
  className: string;
  headerRef: React.RefObject<HTMLDivElement>;
  user: User;
};

export function UserButton({ className, headerRef, user }: Props): JSX.Element {
  const ref = useRef<HTMLButtonElement>(null);
  const overlay = useOverlay();

  useEventListener("keydown", (evt) => {
    if (evt.key === "Escape") {
      overlay.close();
    }
  });

  return (
    <>
      <Tooltip
        containerRef={ref}
        mountIn={headerRef.current}
        disabled={overlay.isOpen}
        placement="bottom"
        offsetPx={-22}
        label="Min profil"
      >
        <button
          ref={ref}
          className={cx(
            className,
            styles.togglerButtonCls,
            overlay.isOpen && "active",
          )}
          onClick={overlay.toggle}
        >
          <span className={styles.togglerButtonContentCls}>
            <UserIcon />
          </span>
        </button>
      </Tooltip>

      <CSSTransition
        in={overlay.isOpen}
        timeout={styles.overlayTransitionDurationMs}
        mountOnEnter
        unmountOnExit
      >
        <div className={styles.overlayCls} onClick={overlay.close}>
          <div
            className={styles.popperCls}
            onClick={(evt) => {
              evt.stopPropagation();
            }}
          >
            <div className={styles.popperContentCls}>
              {user.name}
              <br />
              <a
                className={styles.logoutLinkCls}
                href={getLogoutUrl()}
                rel="noopener noreferrer"
              >
                <LogoutIcon />
                Log ud
              </a>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
}

function useEventListener<K extends keyof WindowEventMap>(
  type: K,
  listener: ((this: Window, ev: WindowEventMap[K]) => void) | undefined,
  options?: boolean | AddEventListenerOptions,
): void {
  useEffect(
    () => {
      if (!listener) {
        return;
      }

      window.addEventListener(type, listener, options);

      return () => {
        window.removeEventListener(type, listener, options);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type, listener, options],
  );
}
