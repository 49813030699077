import { useEffect, useRef } from "react";

import { useRefedState } from "./useRefedState";

type Self = {
  showLoaderTimeout?: number;
  hideLoaderTimeout?: number;

  /**
   * timestamp for when the loader was displayed; used in order to determine
   * when it can be safely hidden again.
   */
  showedLoaderAt?: number;
};

/**
 * conditionally apply loader only after we've been waiting for data for the
 * specified delay; once loader is displayed retain it for at least the defined
 * duration
 */
export function useLoaderState(
  isLoading: boolean,
  {
    delayMs = 125,
    durationMs = 500,
    allowInitial,
    animationIterationMs,
  }: {
    delayMs?: number;
    durationMs?: number;

    /**
     * if enabled, then a throbber will be displayed immediately upon the first
     * display
     */
    allowInitial?: boolean;

    /**
     * if supplied, the loader will not be hidden until the next animation
     * iteration has ended (useful for instance when applying a pulsating
     * animation, and not wanting to abruptly reset while the node is scaled
     * up).
     */
    animationIterationMs?: number;
  } = {},
): React.MutableRefObject<boolean> {
  const self = useRef<Self>({}).current;

  const [showLoader, setShowLoader] = useRefedState<boolean>(
    allowInitial ? isLoading : false,
  );

  useEffect(() => {
    if (isLoading) {
      window.clearTimeout(self.hideLoaderTimeout);

      if (!showLoader.current) {
        window.clearTimeout(self.showLoaderTimeout);

        self.showLoaderTimeout = window.setTimeout(() => {
          setShowLoader(true);

          self.showedLoaderAt = Date.now();
        }, delayMs);
      }
    }

    if (!isLoading) {
      window.clearTimeout(self.showLoaderTimeout);

      if (showLoader.current) {
        window.clearTimeout(self.hideLoaderTimeout);

        // determine how much time will have passed before the loader is going
        // to be hidden based solely on the durationMs and currently elapsed
        // time
        const elapsedDuration = self.showedLoaderAt
          ? Date.now() - self.showedLoaderAt
          : 0;

        let hideLoaderAfter = Math.max(elapsedDuration, durationMs);

        // if provided, then ensure that we do not actually hide the loader
        // until an animation iteration completes
        if (animationIterationMs) {
          hideLoaderAfter =
            Math.ceil(hideLoaderAfter / animationIterationMs) *
            animationIterationMs;
        }

        // now hide the loader once the timeout has been completed
        self.hideLoaderTimeout = window.setTimeout(() => {
          setShowLoader(false);
        }, hideLoaderAfter - elapsedDuration);
      }
    }
  }, [
    isLoading,
    setShowLoader,
    showLoader,
    delayMs,
    durationMs,
    animationIterationMs,
    self,
  ]);

  return showLoader;
}

/**
 * utility that converts a showLoader ref into a promise that's resolved once
 * the loader is no longer displayed
 */
export async function waitForLoaderState(
  state: React.MutableRefObject<boolean>,
): Promise<void> {
  while (state.current) {
    await new Promise((resolve) => setTimeout(resolve, 1));
  }
}
