const DISPLAYED_WARNINGS = new Set<string>();

export function warnOnce(message: string, ...args: string[]): void {
  const warningKey = `${message}:${args.join()}`;

  if (DISPLAYED_WARNINGS.has(warningKey)) {
    // bail out if we've already displayed this warning
    return;
  }

  console.warn(message, ...args);

  DISPLAYED_WARNINGS.add(warningKey);
}
