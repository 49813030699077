import { User } from "../../models/User";
import { WPProductConfig } from "../../models/WPProductConfig";
import { WPTest } from "../../models/WPTest";
import { DAYS_TO_MS, HOURS_TO_MS } from "../constants/time";
import { createCache } from "../createCache";

import { ApiClientOptions } from "./typings/ApiClientOptions";
import { ApiRequestInterceptor } from "./typings/ApiRequestInterceptor";
import { fetchRequest } from "./utils/fetchRequest";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function makeFetchStudentCompletedTests(
  requestInterceptor: ApiRequestInterceptor,
  options: ApiClientOptions,
) {
  const cache = createCache<WPTest[]>({
    timeToLiveMs: 1 * HOURS_TO_MS,
    timeToEvictMs: 1 * DAYS_TO_MS,
    ...options,
  });

  return (user: User, product: WPProductConfig) => {
    return cache(
      `u:${user.id}-i:${user.institutionId}-p:${product.id}`,
      async () => {
        const response = await fetchRequest<WPTest[]>(
          requestInterceptor(
            `${process.env.WP_PORTAL_API}/products/${product.id}/tests/${user.institutionId}/${user.id}/completed`,
          ),
        );

        return response;
      },
    );
  };
}
