import { css } from "@linaria/core";

import { colorPropMixin } from "../../../../styles/mixins/colorProp";
import { fontMixin } from "../../../../styles/mixins/fontMixin";
import { outlineMixin } from "../../../../styles/mixins/outline";
import { buttonReset } from "../../../../styles/resets";
import {
  colorProps,
  easingProps,
  proximaNovaProps,
} from "../../../../styles/variables";

import { lineMixin } from "./lineMixin";

export const TOGGLE_TRANSITION_DURATION_MS = 300;
export const LABEL_CROSSFADE_DURATION_MS = 200;

export const buttonCls = css`
  ${buttonReset};
  ${outlineMixin({ offset: { x: "-1.5rem", y: "-0.5rem" } })};

  padding: 1rem 2rem;
  margin: -1rem -2rem;

  height: 100%;
  width: calc(100% + 4rem);

  display: flex;
  align-items: center;

  color: ${colorPropMixin(colorProps.text)};
  transition: color 0.1s linear;

  &.hovered {
    color: ${colorPropMixin(colorProps.brand)};
  }
`;

export const iconWrapperCls = css`
  position: relative;
  flex-shrink: 0;

  width: 2rem;
  height: 1.2rem;
`;

/**
 * The animation to toggle between the buger menu and the cross is construcuted
 * using the two follwing classes.
 *
 * The pseudo-elements in iconCls are responsible for drawing the top and bottom
 * line of the burger menu. These will fade out quickly when leaving and fade in
 * slowly when entering.
 */
export const iconCls = css`
  &::before,
  &::after {
    ${lineMixin};

    content: "";

    opacity: 1;
    transition: opacity ${TOGGLE_TRANSITION_DURATION_MS * 0.8}ms linear
      ${TOGGLE_TRANSITION_DURATION_MS * 0.2}ms;
  }

  &::before {
    position: absolute;
    top: 0;
  }

  &::after {
    position: absolute;
    bottom: 0;
  }

  .${buttonCls}.toggled & {
    &::before,
    &::after {
      opacity: 0;
      transition: opacity ${TOGGLE_TRANSITION_DURATION_MS * 0.2}ms linear;
    }
  }
`;

/**
 * The crossLineCls renders the two lines that makes out the cross.
 *
 * When not toggled the ::after element renders the middle line of the burger
 * menu and the ::before element is faded out.
 *
 * When toggling the ::after element will rotate the furthest and the ::before
 * element will fade (and rotate 45degress) in after the two lines from the
 * iconCls have completely faded out.
 */
export const crossLinesCls = css`
  &::before,
  &::after {
    ${lineMixin};
    content: "";

    position: absolute;
    top: 50%;
  }

  &::before {
    transform: translateY(-50%) rotate(0deg);
    opacity: 0;

    transition:
      opacity ${TOGGLE_TRANSITION_DURATION_MS * 0.5}ms linear,
      transform ${TOGGLE_TRANSITION_DURATION_MS}ms;
  }

  &::after {
    transform: translateY(-50%) rotate(0deg);

    transition: transform ${TOGGLE_TRANSITION_DURATION_MS * 0.7}ms
      var(${easingProps.outCubic});
  }

  .${buttonCls}.toggled & {
    &::before {
      opacity: 1;
      transform: translateY(-50%) rotate(45deg);

      transition:
        opacity ${TOGGLE_TRANSITION_DURATION_MS * 0.5}ms linear
          ${TOGGLE_TRANSITION_DURATION_MS * 0.2}ms,
        transform ${TOGGLE_TRANSITION_DURATION_MS * 0.8}ms
          var(${easingProps.outCubic}) ${TOGGLE_TRANSITION_DURATION_MS * 0.2}ms;
    }

    &::after {
      transform: translateY(-50%) rotate(135deg);
    }
  }
`;

export const labelWrapperCls = css`
  margin-left: 1.4rem;
  position: relative;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const labelCls = css`
  ${fontMixin(proximaNovaProps, {
    size: "1.6rem",
    weight: "regular",
  })}
  text-transform: uppercase;

  &-enter {
    opacity: 0;

    &-active {
      opacity: 1;
      transition: opacity ${LABEL_CROSSFADE_DURATION_MS}ms linear;
    }
  }

  &-exit {
    display: none;
  }
`;
