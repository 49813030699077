import { cx } from "@linaria/core";
import React, { useLayoutEffect, useRef } from "react";

import { WPFooterColumn } from "../../../../models/WPFooterSection";
import { GyldendalLogoLarge } from "../../../components/icons/PublisherLogo";
import { LayoutBoundary } from "../LayoutBoundary";

import * as styles from "./styles/Footer";

type Props = {
  className?: string;
  columns: WPFooterColumn[];
};

export function Footer({ className, columns }: Props): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const updateHeightProp = () => {
      document.documentElement.style.setProperty(
        styles.footerHeightProp,
        `${ref.current?.offsetHeight ?? 0}px`,
      );
    };

    updateHeightProp();

    if (!ref.current) {
      return;
    }

    const observer = new ResizeObserver(updateHeightProp);

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <footer ref={ref} className={cx(className, styles.footerCls)}>
      <LayoutBoundary className={styles.boundaryCls}>
        <GyldendalLogoLarge className={styles.logoCls} />

        <div className={styles.columnWrapperCls}>
          {columns.map((column, index) => (
            <div key={index} className={styles.columnCls}>
              <b className={styles.columnHeadingCls}>{column.title}</b>
              <ul className={styles.columnLinkListCls}>
                {column.links.map((link, index) => (
                  <li key={index}>
                    <a
                      className={styles.columnLinkCls}
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferer noreferrer"
                    >
                      <span>{link.title}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </LayoutBoundary>
    </footer>
  );
}
