import { User, UserTypes } from "../../models/User";
import { DAYS_TO_MS, HOURS_TO_MS } from "../constants/time";
import { createCache } from "../createCache";

import { ApiClientOptions } from "./typings/ApiClientOptions";
import { ApiRequestInterceptor } from "./typings/ApiRequestInterceptor";
import { fetchRequest } from "./utils/fetchRequest";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function makeFetchUser(
  requestInterceptor: ApiRequestInterceptor,
  options: ApiClientOptions,
) {
  const cache = createCache<Omit<User, "loginConnectorToken">>({
    timeToLiveMs: 1 * HOURS_TO_MS,
    timeToEvictMs: 1 * DAYS_TO_MS,
    ...options,
  });

  return (userId: string) =>
    cache(userId, async () => {
      const response = await fetchRequest<UserResponse>(
        requestInterceptor(
          `${process.env.GAS_API.replaceAll(
            "{{version}}",
            "v1",
          )}/api/v1/user/UnicUser/${encodeURIComponent(userId)}`,
        ),
      );

      const user = {
        id: userId,
        institutionId: response.InstitutionId,
        name: response.FullName,
        type: mapFunctionDescriptionToUserType(response.FunctionDescription),
      };

      if (user.type === UserTypes.STUDENT && user.institutionId) {
        try {
          const institutionResponse = await fetchRequest<{
            INST_TYPE_3?: string | number;
          }>(
            requestInterceptor(
              `${process.env.GAS_API.replaceAll(
                "{{version}}",
                "v1",
              )}/api/v1/unic/Institution/${encodeURIComponent(
                user.institutionId,
              )}`,
            ),
          );

          if (
            institutionResponse.INST_TYPE_3 === 1085 ||
            institutionResponse.INST_TYPE_3 === "1085"
          ) {
            user.type = UserTypes.TEACHER;
          }
        } catch (err) {
          // gracefully handle errors while fetching institution data (simply assume
          // that the student is actually a student)
          console.error(err);
        }
      }

      return user;
    });
}

type UserResponse = {
  FullName: string;
  FunctionDescription: string;
  InstitutionId: string | undefined;
};

function mapFunctionDescriptionToUserType(
  functionDescription: string | undefined,
): UserTypes {
  if (!functionDescription) {
    return UserTypes.TEACHER;
  }

  switch (functionDescription.toLowerCase()) {
    case "elev":
    case "student":
    case "stud":
    case "studerende":
    case "barn":
      return UserTypes.STUDENT;

    default:
      return UserTypes.TEACHER;
  }
}
