const LOGIN_PROVIDER = "gu-unic";

export function getLoginUrl(): string {
  return (
    `${process.env.LOGIN_CONNECTOR_API}/Navigator/Navigator` +
    `?clientWebSite=${LOGIN_PROVIDER}` +
    `&clientWsSuccessUrl=${window.location.href}` +
    `&clientWsFailureUrl=${window.location.href}` +
    `&forceLogin=true`
  );
}

export function getLogoutUrl(): string {
  return (
    `${process.env.LOGIN_CONNECTOR_API}/Navigator/Logout` +
    `?clientWebSite=${LOGIN_PROVIDER}` +
    `&logoutSuccessUrl=${window.location.href}`
  );
}
