import { cx } from "@linaria/core";
import React, { useRef, useLayoutEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import {
  WPAsyncPageGroupDescriptor,
  WPPageDescriptor,
  WPPageGroupDescriptor,
} from "../../../../models/WPPageDescriptor";
import { WPProductConfig } from "../../../../models/WPProductConfig";
import { warnOnce } from "../../../../utils/logs/warnOnce";
import { Chevron } from "../../../components/atoms/Chevron/Chevron";
import { useBreakpoint } from "../../../hooks/context/useBreakpoint";
import { useOverlay } from "../../../hooks/effect/useOverlay";
import { colorProps } from "../../../styles/variables";
import { AppPage } from "../../hooks/usePageFromRouter";
import { fadeInTransitionDurationMs } from "../../styles/App";

import { SubHeaderNav } from "./SubHeaderNav";
import * as styles from "./styles/SubHeader";

const HEX_PRIMARY_COLOR = "#0b58d8";

type Props = {
  page: AppPage | undefined;
};

export function SubHeader({ page }: Props): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const { product, parentPageDescriptor, pageDescriptor, isAuthorized } =
    page ?? {};
  const { belowTablet } = useBreakpoint();

  const overlay = useOverlay();

  useProductColorCssProp(product);
  useSubHeaderHeightCssProp(ref, { product, parentPageDescriptor });

  return (
    <>
      <TransitionGroup component={null}>
        {(parentPageDescriptor || product) && (
          <CSSTransition timeout={fadeInTransitionDurationMs}>
            <header
              ref={ref}
              className={cx(
                styles.subHeaderCls,
                product && "withProductStyling",
              )}
            >
              <TransitionGroup component={null}>
                {(parentPageDescriptor || product) && (
                  <CSSTransition
                    key={product?.id ?? parentPageDescriptor?.path}
                    timeout={fadeInTransitionDurationMs * 0.5}
                  >
                    <div
                      className={cx(
                        styles.containerCls,
                        product && "withProductStyling",
                      )}
                      style={{
                        [colorProps.brand]: convertColorToRgb(product?.color),
                      }}
                    >
                      {belowTablet ? (
                        <button
                          aria-label="Åbn undernavigation"
                          className={styles.buttonCls}
                          onClick={() => {
                            if (isAuthorized !== false) {
                              overlay.toggle();
                            }
                          }}
                        >
                          <SubHeaderContent
                            product={product}
                            pageDescriptor={pageDescriptor}
                            isAuthorized={isAuthorized}
                            isOverlayOpen={overlay.isOpen}
                          />
                        </button>
                      ) : (
                        <SubHeaderContent
                          product={product}
                          pageDescriptor={pageDescriptor}
                          isAuthorized={isAuthorized}
                          isOverlayOpen={overlay.isOpen}
                        />
                      )}
                    </div>
                  </CSSTransition>
                )}
              </TransitionGroup>
            </header>
          </CSSTransition>
        )}
      </TransitionGroup>

      <SubHeaderNav
        page={page}
        toggled={overlay.isOpen}
        onClose={overlay.close}
      />
    </>
  );
}

type ContentProps = {
  product: WPProductConfig | undefined;
  pageDescriptor: WPPageDescriptor | undefined;
  isAuthorized: boolean | undefined;

  isOverlayOpen: boolean;
};

function SubHeaderContent({
  product,
  pageDescriptor,
  isAuthorized,
  isOverlayOpen,
}: ContentProps): JSX.Element {
  return (
    <div className={styles.contentCls}>
      {product && (
        <span className={styles.headingCls}>
          {product.title} {product.grade}
        </span>
      )}
      {!product && pageDescriptor && (
        <span className={styles.headingCls}>{pageDescriptor.title}</span>
      )}
      {isAuthorized !== false && (
        <Chevron
          className={styles.chevronCls}
          size={11}
          vertical
          inversed={isOverlayOpen}
        />
      )}
    </div>
  );
}

function useProductColorCssProp(product: WPProductConfig | undefined): void {
  useLayoutEffect(() => {
    const productColor = convertColorToRgb(product?.color);

    if (product?.color && productColor) {
      const productColorRGB = parseRgbColorString(productColor);

      document.documentElement.style.setProperty(
        colorProps.brand,
        productColor,
      );

      // We also update the theme-color of the head to allow overscrolling in
      // Safari to use the current product color.
      document.head
        .querySelector("[name='theme-color']")
        ?.setAttribute("content", product.color);

      const allowWhiteText = verifyWCAGContrastRatio(productColorRGB, {
        r: 255,
        g: 255,
        b: 255,
      });

      document.documentElement.style.setProperty(
        colorProps.textOnBrand,
        allowWhiteText
          ? `var(${colorProps.textInversed})`
          : `var(${colorProps.text})`,
      );
    } else {
      document.documentElement.style.removeProperty(colorProps.brand);
      document.documentElement.style.removeProperty(colorProps.textOnBrand);
      document.head
        .querySelector("[name='theme-color']")
        ?.setAttribute("content", HEX_PRIMARY_COLOR);
    }
  }, [product]);
}

function useSubHeaderHeightCssProp(
  ref: React.RefObject<HTMLDivElement>,
  props: {
    product: WPProductConfig | undefined;
    parentPageDescriptor:
      | WPPageGroupDescriptor
      | WPAsyncPageGroupDescriptor
      | undefined;
  },
): void {
  useLayoutEffect(() => {
    const updateHeightProp = () => {
      document.documentElement.style.setProperty(
        styles.subHeaderHeightProp,
        `${ref.current?.offsetHeight ?? 0}px`,
      );
    };

    updateHeightProp();

    if (!ref.current) {
      return;
    }

    const observer = new ResizeObserver(updateHeightProp);

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, props.product, props.parentPageDescriptor]);
}

function convertColorToRgb(color: string | undefined): string | undefined {
  if (!color) {
    return;
  }

  if (!color.startsWith("#")) {
    warnOnce(
      "convertColorToRgb(): invalid color '%s' given, only hex is supported!",
      color,
    );
    return;
  }

  if (color.length === 4) {
    const r = parseInt(color.substr(1, 1), 16);
    const g = parseInt(color.substr(2, 1), 16);
    const b = parseInt(color.substr(3, 1), 16);

    return `${r * 16 + r}, ${g * 16 + g}, ${b * 16 + b}`;
  }

  if (color.length === 7) {
    const r = parseInt(color.substr(1, 2), 16);
    const g = parseInt(color.substr(3, 2), 16);
    const b = parseInt(color.substr(5, 2), 16);

    return `${r}, ${g}, ${b}`;
  }

  warnOnce(
    "convertColorToRgb(): invalid color '%s' given, only #rgb or #rrggbb is supported!",
    color,
  );
  return;
}

type RgbColor = {
  r: number;
  g: number;
  b: number;
};

function parseRgbColorString(str: string): RgbColor {
  const [r, g, b] = str.split(",").map((it) => parseInt(it.trim(), 10));

  return {
    r: r || 0,
    g: g || 0,
    b: b || 0,
  };
}

/**
 * determine if the contrast ratio between the two given colors is high enough
 * to meet the criterias defined by WCAG
 *
 * based on the contrast ratio definition from W3C:
 * @see https://www.w3.org/TR/WCAG20/#contrast-ratiodef
 *
 * and the thresholds defined by WCAG:
 * @see https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html
 * @see https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast7.html
 */
// Based on https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color

function verifyWCAGContrastRatio(
  color1: RgbColor | undefined,
  color2: RgbColor | undefined,
  options: {
    theshold?: "AA" | "AAA";
    largeText?: boolean;
  } = {},
): boolean {
  if (!color1 || !color2) {
    return false;
  }

  // determine minimum allowed contrast ratio based on options
  const threshold =
    options.theshold !== "AAA"
      ? options.largeText
        ? 3
        : 4.5
      : options.largeText
        ? 4.5
        : 7;

  // calculate luminosity for both given colors, and check to see if we meet the
  // required threshold
  const L1 = calculateColorLuminosity(color1) + 0.05;
  const L2 = calculateColorLuminosity(color2) + 0.05;

  return Math.max(L1, L2) / Math.min(L1, L2) >= threshold;
}

/**
 * calculates relative luminosity of an rgb color
 * @see https://www.w3.org/TR/WCAG20/#relativeluminancedef
 */
function calculateColorLuminosity(color: RgbColor): number {
  const [r, g, b] = [color.r, color.g, color.b].map((c) => {
    c = c / 255;

    if (c <= 0.03928) {
      return c / 12.92;
    } else {
      return ((c + 0.055) / 1.055) ** 2.4;
    }
  }) as [number, number, number];

  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}
