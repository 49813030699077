import { createContext, useContext } from "react";

import { ApiClient, makeApiClient } from "../../../utils/api/_makeApiClient";

const ApiClientContext = createContext<ApiClient>(
  window.location.hostname.includes("preview")
    ? makeApiClient(
        (url, requestInit) =>
          new Request(
            url.replace(
              "api-webproever.gyldendal.dk",
              "api-preview-webproever.gyldendal.dk",
            ),
            requestInit,
          ),
      )
    : makeApiClient((url, requestInit) => new Request(url, requestInit)),
);

export function useApiClient(): ApiClient {
  return useContext(ApiClientContext);
}
