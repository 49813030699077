import { cx } from "@linaria/core";
import React, { forwardRef } from "react";

import * as styles from "./styles/ContentBoundary";

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const ContentBoundary = forwardRef<HTMLDivElement, Props>(
  function ContentBoundary({ className, children }, ref): JSX.Element {
    return (
      <div ref={ref} className={cx(className, styles.boundaryCls)}>
        {children}
      </div>
    );
  },
);
