import { css } from "@linaria/core";

import { buttonStyle } from "../../../../styles/defaults";
import { colorPropMixin } from "../../../../styles/mixins/colorProp";
import { darkenOnHoverMixin } from "../../../../styles/mixins/darkenOnHover";
import { colorProps } from "../../../../styles/variables";

export const buttonCls = css`
  ${buttonStyle};
`;

export const primaryCls = css`
  background: ${colorPropMixin(colorProps.primary)} !important;
  color: ${colorPropMixin(colorProps.textInversed)};

  ${darkenOnHoverMixin(0.1)};
`;
