import { WPVideoElement } from "../../../../models/WPContentBlock";

export async function fetchVideoElement(
  model: WPVideoElement,
): Promise<JSX.Element> {
  const { VideoElement } = await import(
    "../../../components/blocks/VideoElement/VideoElement"
  );

  return <VideoElement model={model} />;
}
