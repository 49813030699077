import { cx } from "@linaria/core";

import * as styles from "./styles/svg";
import { normalizeStrokeWidth } from "./utils/normalizeStrokeWidth";

type Props = {
  className?: string;
  strokeWidth?: number;
  height?: string | number;
  color?: string;
};

export function ExternalLinkIcon({
  className,
  height,
  strokeWidth = 2,
  color = "currentColor",
}: Props): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.277 20.277"
      className={cx(styles.svgCls, className)}
      fill="none"
      stroke={color}
      height={height}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={normalizeStrokeWidth(strokeWidth, 20.277)}
    >
      <path d="m19.076 6.67v-5.468h-5.469" />
      <path d="m19.075 1.202-12.15 12.15" />
      <path d="m9.355 4.847h-7.29a1.215 1.215 0 0 0 -1.215 1.215v12.15a1.215 1.215 0 0 0 1.215 1.215h12.15a1.215 1.215 0 0 0 1.215-1.215v-7.29" />
    </svg>
  );
}
