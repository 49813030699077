import { useCallback, useState, useEffect } from "react";

import { useActivePagePath } from "../../app/hooks/useActivePagePath";

type Return = {
  isOpen: boolean;

  open: () => void;
  close: () => void;
  toggle: () => void;
};

const overlayCloseCallbacks = new Set<() => void>();

export function useOverlay(): Return {
  const [showOverlay, setShowOverlay] = useState(false);
  const path = useActivePagePath();

  const close = useCallback(() => {
    setShowOverlay(false);
  }, []);

  const closeOtherOverlays = useCallback(() => {
    for (const onClose of overlayCloseCallbacks) {
      if (onClose !== close) {
        onClose();
      }
    }
  }, [close]);

  const open = useCallback(() => {
    closeOtherOverlays();
    setShowOverlay(true);
  }, [closeOtherOverlays]);

  const toggle = useCallback(() => {
    setShowOverlay((prev) => {
      const next = !prev;

      if (next) {
        closeOtherOverlays();
      }

      return next;
    });
  }, [closeOtherOverlays]);

  useEffect(() => {
    overlayCloseCallbacks.add(close);

    return () => {
      overlayCloseCallbacks.delete(close);
    };
  }, [showOverlay, close]);

  useEffect(() => {
    // auto-close on navigation
    setShowOverlay(false);
  }, [path]);

  return {
    isOpen: showOverlay,
    open,
    close,
    toggle,
  };
}
