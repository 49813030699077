import { css } from "@linaria/core";

import { easingProps } from "../../../../styles/variables";

const propPrefix = "--underline-active-element";

export const underlineOffsetProp = `${propPrefix}--offset`;
export const underlineSizeProp = `${propPrefix}--size`;
export const underlineColorProp = `${propPrefix}--color`;
export const underlineTransitionDurationMs = 175;

export const wrapperCls = css`
  ${underlineOffsetProp}: 0px;
  ${underlineSizeProp}: 0px;
  ${underlineColorProp}: currentColor;

  position: relative;

  &::after {
    content: "";

    position: absolute;
    pointer-events: none;
    opacity: 0;

    background-color: var(${underlineColorProp});
  }
`;

export const horizontalCls = css`
  &::after {
    bottom: 0;
    left: 0;
    height: 3px;

    width: var(${underlineSizeProp});
    transform: translateX(var(${underlineOffsetProp}));
  }
`;

export const verticalCls = css`
  &::after {
    top: 0;
    left: 0;
    width: 3px;

    height: var(${underlineSizeProp});
    transform: translateY(var(${underlineOffsetProp}));
  }
`;

export const readyCls = css`
  &::after {
    /**
     * don'y apply any transitions until the "ready" class has been added to the
     * container; this prevents a transition from happening on the initial
     * mount, where we instead rely on initial enter transition of the entire
     * layout
     */
    transition:
      opacity ${underlineTransitionDurationMs}ms linear,
      width ${underlineTransitionDurationMs}ms var(${easingProps.inOutQuad}),
      height ${underlineTransitionDurationMs}ms var(${easingProps.inOutQuad});
  }
`;

export const visibleCls = css`
  &::after {
    opacity: 1;

    /**
     * apply transition on the offset only when the underline was already
     * visible
     *
     * this way, if the underline had disappeared, then it will simply appear
     * to be drawn under the active item once re-enabled; not scale in all the
     * way from the left side
     */
    transition:
      opacity ${underlineTransitionDurationMs}ms linear,
      width ${underlineTransitionDurationMs}ms var(${easingProps.inOutQuad}),
      height ${underlineTransitionDurationMs}ms var(${easingProps.inOutQuad}),
      transform ${underlineTransitionDurationMs}ms var(${easingProps.inOutQuad})
        15ms;
  }
`;
