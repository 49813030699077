export enum UserTypes {
  TEACHER = "teacher",
  STUDENT = "student",
}

export type User = {
  loginConnectorToken: string;
  id: string;
  institutionId: string | undefined;
  name: string;
  type: UserTypes;
};
