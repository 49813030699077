import React from "react";
import { Transition } from "react-transition-group";

import { easingProps } from "../../styles/variables";

type Props = {
  in?: boolean;
  children: React.ReactNode;
  timeout: number;

  classNames?: string;
  mountOnEnter?: boolean;
  unmountOnExit?: boolean;

  additionalOverflowPx?: number;

  /**
   * in case the transitioing item has negative margins, then this property
   * needs to be set to the inverse of that to achieve the desired transition.
   * (e.g. -10px margin-top -> offsetPx={10})
   */
  offsetPx?: number;
};

export const HeightTransition: React.FC<Props> = (props) => {
  const offsetPx = props.offsetPx ?? 0;

  const onEnter = (element: HTMLElement): void => {
    if (!element) {
      return;
    }

    element.style.overflowY = "hidden";
    element.style.height = `${offsetPx}px`;

    removeClassname(element, props.classNames, "exit");
    removeClassname(element, props.classNames, "exit-active");
    addClassname(element, props.classNames, "enter");
  };

  const onEnterActive = (element: HTMLElement): void => {
    if (!element) {
      return;
    }

    setTimeout(() => {
      const desiredHeight = element.scrollHeight;
      const maxHeight =
        window.innerHeight +
        (props.additionalOverflowPx ?? 0) -
        element.getBoundingClientRect().top;

      element.style.height = `${Math.min(desiredHeight, maxHeight)}px`;
      element.style.transition = `height ${props.timeout}ms var(${easingProps.outQuart})`;

      addClassname(element, props.classNames, "enter-active");
    });
  };

  function onEntered(element: HTMLElement): void {
    if (!element) {
      return;
    }

    element.style.overflowY = "";
    element.style.height = "";
    element.style.transition = "";
    removeClassname(element, props.classNames, "enter");
    removeClassname(element, props.classNames, "enter-active");
  }

  function onExit(element: HTMLElement): void {
    if (!element) {
      return;
    }

    const desiredHeight = element.scrollHeight;
    const maxHeight =
      window.innerHeight +
      (props.additionalOverflowPx ?? 0) -
      element.getBoundingClientRect().top;

    element.style.overflowY = "hidden";
    element.style.height = `${Math.min(desiredHeight, maxHeight)}px`;

    removeClassname(element, props.classNames, "enter");
    removeClassname(element, props.classNames, "enter-active");
    addClassname(element, props.classNames, "exit");
  }

  function onExitActive(element: HTMLElement): void {
    if (!element) {
      return;
    }

    element.offsetHeight;
    element.style.height = `${offsetPx}px`;
    element.style.transition = `height ${props.timeout}ms var(${easingProps.outQuart})`;

    addClassname(element, props.classNames, "exit-active");
  }

  return (
    <Transition<undefined>
      in={props.in}
      timeout={props.timeout}
      mountOnEnter={props.mountOnEnter}
      unmountOnExit={props.unmountOnExit}
      onEnter={onEnter}
      onEntering={onEnterActive}
      onEntered={onEntered}
      onExit={onExit}
      onExiting={onExitActive}
    >
      {props.children}
    </Transition>
  );
};

function addClassname(
  element: HTMLElement | null,
  className: string | undefined,
  postfix: string,
): void {
  if (!element) {
    return;
  }

  if (className) {
    element.classList.add(`${className}-${postfix}`);
  } else {
    element.classList.add(postfix);
  }
}

function removeClassname(
  element: HTMLElement | null,
  className: string | undefined,
  postfix: string,
): void {
  if (!element) {
    return;
  }

  if (className) {
    element.classList.remove(`${className}-${postfix}`);
  } else {
    element.classList.remove(postfix);
  }
}
