import { css } from "@linaria/core";

import { boundaryPaddingProp } from "../../../styles/variables";

export const boundaryCls = css`
  --desired-width: 111rem;

  box-sizing: border-box;
  width: calc(var(--desired-width) + var(${boundaryPaddingProp}) * 2);
  max-width: 100%;

  padding: 0 var(${boundaryPaddingProp});
  margin: 0 auto;
`;
