import { WPNavigationLink } from "../../../../../models/WPNavigationLink";
import {
  WPPageDescriptor,
  WPPageGroupDescriptor,
} from "../../../../../models/WPPageDescriptor";
import { UnderlineActiveElement } from "../../../../components/atoms/UnderlineActiveElement/UnderlineActiveElement";
import { colorProps } from "../../../../styles/variables";
import { useActivePagePath } from "../../../hooks/useActivePagePath";

import { NavigationLink } from "./NavigationLink";
import * as styles from "./styles/Navigation";

type Props = {
  navigation:
    | (WPPageDescriptor | WPPageGroupDescriptor | WPNavigationLink)[]
    | undefined;
};

export function Navigation({ navigation }: Props): JSX.Element | null {
  const activePath = useActivePagePath();

  if (!navigation) {
    return null;
  }

  return (
    <nav className={styles.navigationCls}>
      <UnderlineActiveElement
        className={styles.underlineCls}
        activeSelector={`[aria-current="location"], [aria-current="page"]`}
        color={`rgb(var(${colorProps.primary}))`}
      >
        <ul className={styles.linkListCls}>
          {navigation.map((it, index) => (
            <li key={index}>
              <NavigationLink
                activePath={activePath}
                className={styles.linkCls}
                model={it}
              />
            </li>
          ))}
        </ul>
      </UnderlineActiveElement>
    </nav>
  );
}
