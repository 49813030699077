import { css } from "@linaria/core";

import { contentPaddingProp } from "../../../../app/layout/styles/PageWrapper";
import { TABLET_BREAKPOINT_PX } from "../../../../styles/constants";
import { h1Style, paragraphStyle } from "../../../../styles/defaults";
import { breakpointDownMixin } from "../../../../styles/mixins/breakpoints";
import { colorPropMixin } from "../../../../styles/mixins/colorProp";
import { colorProps } from "../../../../styles/variables";

export const wrapperCls = css`
  background-color: ${colorPropMixin(colorProps.grey_50)};

  &:first-child {
    /**
     * Dangerous; hardcoded pull-up so that there is no white-space on top of
     * the hero on the frontpage
     */
    margin-top: calc(var(${contentPaddingProp}) * -1);
  }
`;

export const boundaryCls = css`
  position: relative;
  padding-top: 8rem;
  padding-bottom: 9rem;

  @media screen and (${breakpointDownMixin(TABLET_BREAKPOINT_PX)}) {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
`;

export const imageCls = css`
  position: absolute;
  bottom: -3rem;
  right: -6rem;

  width: calc(56.75% + 6rem);

  @media screen and (${breakpointDownMixin(TABLET_BREAKPOINT_PX)}) {
    display: none;
  }
`;

export const headingCls = css`
  ${h1Style};
  font-size: 3rem;

  width: calc(100% + 6rem - (56.75% + 6rem) * 0.8);

  @media screen and (${breakpointDownMixin(TABLET_BREAKPOINT_PX)}) {
    width: 100%;
    max-width: 69rem;
  }
`;

export const bodyCls = css`
  ${paragraphStyle};

  width: 43.25%;

  @media screen and (${breakpointDownMixin(TABLET_BREAKPOINT_PX)}) {
    width: 100%;
    max-width: 69rem;
  }
`;
