import { User } from "../../../../models/User";
import { WPApiBlock } from "../../../../models/WPContentBlock";
import { WPProductConfig } from "../../../../models/WPProductConfig";
import { ApiClient } from "../../../../utils/api/_makeApiClient";

export async function fetchStudentCompletedTests(
  apiClient: ApiClient,
  user: User | undefined,
  product: WPProductConfig | undefined,
  block: WPApiBlock,
): Promise<JSX.Element | null> {
  if (!user || !product) {
    return null;
  }

  const [tests, { StudentCompletedTests }] = await Promise.all([
    apiClient.fetchStudentCompletedTests(user, product),
    import(
      "../../../components/blocks/StudentCompletedTests/StudentCompletedTests"
    ),
  ]);

  return <StudentCompletedTests model={block} tests={tests} />;
}
