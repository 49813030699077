import { useState, useRef } from "react";

/**
 * wrapper around useState that exposes the actual state value as a mutable ref,
 * which is useful if we need to be able to access the current value outside the
 * render cycle, but also want changes to trigger re-rendering.
 */
export function useRefedState<T extends string | number | boolean>(
  initialState: T | (() => T),
): [React.MutableRefObject<T>, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = useState(initialState);

  const stateRef = useRef(state);
  const setStateRef = useRef((nextValue: T | ((prevValue: T) => T)) => {
    stateRef.current =
      typeof nextValue === "function" ? nextValue(stateRef.current) : nextValue;

    setState(stateRef.current);
  });

  return [stateRef, setStateRef.current];
}
