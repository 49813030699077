import { MaybePromise } from "../typings/MaybePromise";

export function createCache<T>(config: {
  timeToLiveMs: number;
  timeToEvictMs: number;
  disableCache?: boolean;
}): (key: string, fetcher: () => MaybePromise<T>) => MaybePromise<T> {
  const cache = new Map<
    string,
    {
      createdAt: number;
      value: MaybePromise<T>;
    }
  >();
  const evictTimeoutMap = new Map<string, number>();

  return (key, fetcher) => {
    async function fetchRevalidatedValue(): Promise<T> {
      try {
        const value = fetcher();

        clearTimeout(evictTimeoutMap.get(key));

        if (!config.disableCache) {
          const createdAt = Date.now();

          cache.set(key, {
            createdAt,
            value,
          });

          Promise.resolve(value)
            // update cached value if it is still available
            .then((resolvedValue) => {
              if (cache.has(key)) {
                cache.set(key, {
                  createdAt,
                  value: resolvedValue,
                });
              }
            })
            // instantly remove failed promises from cache
            .catch(() => {
              cache.delete(key);
            });
        }

        return value;
      } finally {
        evictTimeoutMap.set(
          key,
          setTimeout(() => {
            cache.delete(key);
          }, config.timeToEvictMs) as unknown as number,
        );
      }
    }

    const result = cache.get(key);

    if (!result) {
      // if no cached result exists, then fetch an updated value and return
      // value once it has been resolved
      return fetchRevalidatedValue();
    }

    if (result.createdAt < Date.now() - config.timeToLiveMs) {
      // if time to live has expired, then fetch an updated value in the
      // background
      fetchRevalidatedValue();
    }

    return result.value;
  };
}
