import { css } from "@linaria/core";

import { TABLET_BREAKPOINT_PX } from "../../../../styles/constants";
import { breakpointDownMixin } from "../../../../styles/mixins/breakpoints";
import { verticalMarginMixin } from "../../../../styles/mixins/collapsableMargin";
import { colorPropMixin } from "../../../../styles/mixins/colorProp";
import { listReset } from "../../../../styles/resets";
import { colorProps, headerPaddingProp } from "../../../../styles/variables";
import { fadeInTransitionDurationMs } from "../../../styles/App";
import { footerHeightProp } from "../../Footer/styles/Footer";
import { headerHeightProp } from "../../Header/styles/Header";

export const subNavigationWidthProp = "--sub-navigation--width";

export const asideCls = css`
  :global(:root) {
    ${subNavigationWidthProp}: 30rem;
  }

  position: absolute;
  top: var(${headerHeightProp});
  bottom: var(${footerHeightProp});
  left: 0;

  width: var(${subNavigationWidthProp});

  box-sizing: border-box;
  border-right: 1px solid ${colorPropMixin(colorProps.grey_100)};

  &.enter {
    opacity: 0.001;

    &-active {
      opacity: 1;
      transition: opacity ${fadeInTransitionDurationMs}ms linear;
    }
  }

  &.exit {
    display: none;
  }

  /**
   * hide the SubNavigation once there is no longer room for it without causing
   * content to be squished together
   */
  @media screen and (${breakpointDownMixin(TABLET_BREAKPOINT_PX)}) {
    display: none;

    :global(:root) {
      ${subNavigationWidthProp}: 0rem;
    }
  }
`;

export const navCls = css`
  position: absolute;
  inset: 0;

  &.enter {
    opacity: 0.001;

    &-active {
      opacity: 1;
      transition: opacity ${fadeInTransitionDurationMs}ms linear;
    }
  }

  &.exit {
    display: none;
  }
`;

export const skeletonCls = css`
  ${listReset};

  padding: 5rem var(${headerPaddingProp});
`;

export const skeletonLineCls = css`
  width: 100%;
  height: 2rem;
  background: ${colorPropMixin(colorProps.grey_100)};

  ${verticalMarginMixin("2rem")};
`;
