import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { User } from "../../../models/User";
import { WPNavigationLink } from "../../../models/WPNavigationLink";
import {
  WPAsyncPageGroupDescriptor,
  WPPageDescriptor,
  WPPageGroupDescriptor,
} from "../../../models/WPPageDescriptor";
import { WPProductConfig } from "../../../models/WPProductConfig";
import { ApiClient } from "../../../utils/api/_makeApiClient";
import { NOT_FOUND_ERROR } from "../../../utils/api/utils/fetchRequest";
import { isAsyncPageGroupDescriptor } from "../../../utils/guards/isAsyncPageGroupDescriptor";
import { isPageDescriptor } from "../../../utils/guards/isPageDescriptor";
import { isPageGroupDescriptor } from "../../../utils/guards/isPageGroupDescriptor";
import { isProduct } from "../../../utils/guards/isProduct";
import { useApiClient } from "../../hooks/context/useApiClient";
import { useUser } from "../../hooks/context/useUser";
import { ErrorBoundaryMessage } from "../pages/ErrorBoundary/ErrorBoundaryMessage";
import { NotFound } from "../pages/NotFound/NotFound";
import { fetchPageContent } from "../pages/_fetchPageContent";

export type AppPage = {
  path: string;
  pageDescriptor: WPPageDescriptor | undefined;
  isAuthorized: boolean;
  parentPageDescriptor?: WPPageGroupDescriptor | WPAsyncPageGroupDescriptor;
  product?: WPProductConfig;
  subNavigation?: (
    | WPPageDescriptor
    | WPPageGroupDescriptor
    | WPAsyncPageGroupDescriptor
  )[];
  content: JSX.Element;
};

export function usePageFromRouter(): AppPage | undefined {
  const apiClient = useApiClient();
  const user = useUser();
  const location = useLocation();

  const [currPage, setPage] = useState<AppPage | undefined>(undefined);

  useEffect(() => {
    if (user === null) {
      // wait until single sign on has finished running, before trying to fetch
      // page content
      return;
    }

    const isCancelled = {
      current: false,
    };

    fetchPageByPath(apiClient, user, location.pathname)
      .then((page) => {
        if (isCancelled.current) {
          return;
        }

        setPage(page);
      })
      .catch((reason) => {
        if (reason !== NOT_FOUND_ERROR) {
          console.error(reason);
        }

        setPage({
          path: location.pathname,
          parentPageDescriptor: undefined,
          pageDescriptor: undefined,
          isAuthorized: true,
          content:
            reason !== NOT_FOUND_ERROR ? (
              <ErrorBoundaryMessage />
            ) : (
              <NotFound />
            ),
        });
      });

    return () => {
      isCancelled.current = true;
    };
  }, [apiClient, user, location.pathname]);

  return currPage;
}

async function fetchPageByPath(
  apiClient: ApiClient,
  user: User | undefined,
  path: string,
): Promise<AppPage> {
  const {
    pageDescriptor,
    parentPageDescriptor,
    subNavigation,
    product,
    isAuthorized,
  } = await resolvePageByPath(apiClient, user, path);

  return {
    path,
    parentPageDescriptor,
    pageDescriptor,
    isAuthorized,
    product,
    subNavigation,
    content: await fetchPageContent(
      apiClient,
      user,
      product,
      isAuthorized,
      pageDescriptor,
    ),
  };
}

async function resolvePageByPath(
  apiClient: ApiClient,
  user: User | undefined,
  path: string,
): Promise<{
  isAuthorized: boolean;
  product: WPProductConfig | undefined;
  parentPageDescriptor:
    | WPPageGroupDescriptor
    | WPAsyncPageGroupDescriptor
    | undefined;
  subNavigation:
    | (WPPageDescriptor | WPPageGroupDescriptor | WPAsyncPageGroupDescriptor)[]
    | undefined;
  pageDescriptor: WPPageDescriptor | undefined;
}> {
  const product = window._wp.ssr.products.filter(isProduct).find((it) => {
    return path.startsWith(`${it.path}/`);
  });

  if (product) {
    const [isAuthorized, subNavigation] = await Promise.all([
      apiClient.isAuthorized(user, [product.isbn]),
      apiClient.fetchProductNavigation(user, product),
    ]);

    return {
      isAuthorized: !!isAuthorized[String(product.isbn)],
      product,
      parentPageDescriptor: subNavigation
        .filter(
          (it): it is WPPageGroupDescriptor | WPAsyncPageGroupDescriptor =>
            isPageGroupDescriptor(it) || isAsyncPageGroupDescriptor(it),
        )
        .find((it) => path.startsWith(`${it.path}/`)),
      subNavigation,
      pageDescriptor: await recursivelyFindPageDescriptorByPath(
        subNavigation,
        path,
      ),
    };
  }

  const navigation = await apiClient.fetchNavigation(user);
  const parentPageDescriptor = navigation
    .filter(isPageGroupDescriptor)
    .find((it) => path.startsWith(`${it.path}/`));

  return {
    isAuthorized: true,
    product: undefined,
    parentPageDescriptor: parentPageDescriptor,
    subNavigation: parentPageDescriptor?.pages,
    pageDescriptor: await recursivelyFindPageDescriptorByPath(navigation, path),
  };
}

async function recursivelyFindPageDescriptorByPath(
  descriptors: (
    | WPPageDescriptor
    | WPPageGroupDescriptor
    | WPAsyncPageGroupDescriptor
    | WPNavigationLink
  )[],
  path: string,
): Promise<WPPageDescriptor | undefined> {
  for (const descriptor of descriptors) {
    if (isPageDescriptor(descriptor) && descriptor.path === path) {
      return descriptor;
    }

    if (isPageGroupDescriptor(descriptor) && descriptor.pages) {
      const nestedPageDescriptor = await recursivelyFindPageDescriptorByPath(
        descriptor.pages,
        path,
      );

      if (nestedPageDescriptor !== undefined) {
        return nestedPageDescriptor;
      }
    }

    if (isAsyncPageGroupDescriptor(descriptor)) {
      const nestedPageDescriptor = await recursivelyFindPageDescriptorByPath(
        await descriptor.getPages(),
        path,
      );

      if (nestedPageDescriptor !== undefined) {
        return nestedPageDescriptor;
      }
    }
  }
}
