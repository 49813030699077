import { WPImageElement } from "../../../../models/WPContentBlock";

export async function fetchImageElement(
  model: WPImageElement,
): Promise<JSX.Element> {
  const { ImageElement } = await import(
    "../../../components/blocks/ImageElement/ImageElement"
  );

  return <ImageElement model={model} />;
}
