import { WPTextElement } from "../../../../models/WPContentBlock";

export async function fetchTextElement(
  model: WPTextElement,
): Promise<JSX.Element> {
  const { TextElement } = await import(
    "../../../components/blocks/TextElement/TextElement"
  );

  return <TextElement model={model} />;
}
