import { cx } from "@linaria/core";
import React from "react";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import {
  WPAsyncPageGroupDescriptor,
  WPPageDescriptor,
  WPPageGroupDescriptor,
} from "../../../../models/WPPageDescriptor";
import { isPageGroupDescriptor } from "../../../../utils/guards/isPageGroupDescriptor";
import { useActivePagePath } from "../../hooks/useActivePagePath";

import * as styles from "./styles/MobileLink";

export function MobileLinkItem({
  item,
  disabled,
  spin,
  onClick,
}: {
  item: WPPageDescriptor | WPPageGroupDescriptor | WPAsyncPageGroupDescriptor;
  disabled?: boolean;
  spin?: boolean;

  onClick?(evt: React.MouseEvent<HTMLAnchorElement>): void;
}): JSX.Element {
  const activePagePath = useActivePagePath();

  return (
    <Link
      to={
        !isPageGroupDescriptor(item) ? item.path : item.pages?.[0]?.path ?? ""
      }
      className={cx(styles.linkCls, disabled && "disabled")}
      aria-current={item.path === activePagePath ? "page" : undefined}
      onClick={onClick}
    >
      <span data-wp-outline>{item.title}</span>

      <CSSTransition
        in={!!spin}
        timeout={styles.spinnerFadeDurationMs}
        mountOnEnter
        unmountOnExit
      >
        <span className={styles.spinnerCls} />
      </CSSTransition>
    </Link>
  );
}
