import { WPApiBlock } from "../../../../models/WPContentBlock";

export async function fetchAllProducts(
  block: WPApiBlock,
): Promise<JSX.Element> {
  const { ProductList } = await import(
    "../../../components/blocks/ProductList/ProductList"
  );

  return (
    <ProductList model={block} products={window._wp.ssr.products} subHeading />
  );
}
