import { useEffect, useState, createContext, useContext } from "react";

import {
  MOBILE_BREAKPOINT_PX,
  TABLET_BREAKPOINT_PX,
} from "../../styles/constants";
import { breakpointDownMixin } from "../../styles/mixins/breakpoints";

type Context = {
  belowTablet: boolean;
  belowMobile: boolean;
};

export const tabletMQL = matchMedia(
  `(${breakpointDownMixin(TABLET_BREAKPOINT_PX)})`,
);
export const mobileMQL = matchMedia(
  `(${breakpointDownMixin(MOBILE_BREAKPOINT_PX)})`,
);

const BreakpointContext = createContext<Context>({
  belowTablet: tabletMQL.matches,
  belowMobile: mobileMQL.matches,
});

export function BreakpointProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [contextValue, setContextValue] = useState<Context>({
    belowTablet: tabletMQL.matches,
    belowMobile: mobileMQL.matches,
  });

  useEffect(() => {
    tabletMQL.addEventListener("change", (evt) => {
      setContextValue((prev) => ({
        ...prev,
        belowTablet: evt.matches,
      }));
    });

    mobileMQL.addEventListener("change", (evt) => {
      setContextValue((prev) => ({
        ...prev,
        belowMobile: evt.matches,
      }));
    });
  }, []);

  return (
    <BreakpointContext.Provider value={contextValue}>
      {children}
    </BreakpointContext.Provider>
  );
}

export function useBreakpoint(): Context {
  return useContext(BreakpointContext);
}
