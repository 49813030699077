import { useRef } from "react";

import { KeyIcon } from "../../../components/icons/KeyIcon";
import { Tooltip } from "../../../components/poppers/Tooltip/Tooltip";
import { getLoginUrl } from "../../../utils/api/loginConnector";

import * as styles from "./styles/LoginButton";

type Props = {
  className: string;
  headerRef: React.RefObject<HTMLDivElement>;
};

export function LoginButton({ className, headerRef }: Props): JSX.Element {
  const ref = useRef<HTMLAnchorElement>(null);

  return (
    <Tooltip
      mountIn={headerRef.current}
      placement="bottom"
      offsetPx={-22}
      containerRef={ref}
      label="Log ind"
    >
      <a
        ref={ref}
        className={className}
        href={getLoginUrl()}
        rel="noopener noreferrer"
      >
        <span className={styles.contentCls}>
          <KeyIcon />
        </span>
      </a>
    </Tooltip>
  );
}
