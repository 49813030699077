import { User } from "../../../../models/User";
import { WPApiBlock } from "../../../../models/WPContentBlock";
import { WPProductConfig } from "../../../../models/WPProductConfig";
import { ApiClient } from "../../../../utils/api/_makeApiClient";

export async function fetchTeacherAvailableTests(
  apiClient: ApiClient,
  user: User | undefined,
  product: WPProductConfig | undefined,
  block: WPApiBlock,
): Promise<JSX.Element | null> {
  if (!user || !product) {
    return null;
  }

  const [testGroups, { TeacherAvailableTests }] = await Promise.all([
    apiClient.fetchTeacherAvailableTests(user, product),
    import(
      "../../../components/blocks/TeacherAvailableTests/TeacherAvailableTests"
    ),
  ]);

  return <TeacherAvailableTests model={block} testGroups={testGroups} />;
}
