import { css } from "@linaria/core";

import { TABLET_BREAKPOINT_PX } from "../../../../styles/constants";
import { breakpointDownMixin } from "../../../../styles/mixins/breakpoints";
import { verticalMarginMixin } from "../../../../styles/mixins/collapsableMargin";
import { listReset } from "../../../../styles/resets";
import { headerPaddingProp } from "../../../../styles/variables";

const underlineOffsetProp = "--underline-offset";

export const stickyCls = css`
  ${underlineOffsetProp}: 3rem;

  box-sizing: border-box;
  width: calc(100% - var(--margin-left));
  max-height: 100%;
  overflow-y: auto;

  padding: 4.5rem 3rem 4.5rem
    calc(var(${headerPaddingProp}) - var(${underlineOffsetProp}));
`;

export const activeUnderlineCls = css`
  /** custom styling of underline */
  &::after {
    width: 2px;
    border-radius: 1px;
  }
`;

export const listCls = css`
  ${listReset};
`;

export const itemCls = css`
  position: relative;

  padding-left: var(${underlineOffsetProp});
  ${verticalMarginMixin("1rem")};

  @media screen and (${breakpointDownMixin(TABLET_BREAKPOINT_PX)}) {
    margin: 0;
  }
`;
