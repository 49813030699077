import {
  WPProductConfig,
  WPProductLinkConfig,
  WPProductTypes,
} from "../../models/WPProductConfig";

export function isProduct(
  product: WPProductConfig | WPProductLinkConfig,
): product is WPProductConfig {
  return product.type === WPProductTypes.PRODUCT;
}
