import { css } from "@linaria/core";

import { colorPropMixin } from "../../../../styles/mixins/colorProp";
import { hoverColorMixin } from "../../../../styles/mixins/hoverColor";
import { outlineMixin } from "../../../../styles/mixins/outline";
import { anchorReset } from "../../../../styles/resets";
import {
  colorProps,
  easingProps,
  headerPaddingProp,
  proximaNovaProps,
} from "../../../../styles/variables";

import { headerHeightProp } from "./Header";
import { subHeaderHeightProp } from "./SubHeader";

export const overlayTransitionDurationMs = 175;

export const togglerButtonCls = css`
  &.active {
    /**
     * force icon to be highlighted while the dropdown is expanded; this will
     * circumvent the fade-out that is added by default when hovering the
     * navbar.
     */
    opacity: 1 !important;
  }
`;

export const togglerButtonContentCls = css`
  display: flex;
  justify-content: center;

  width: 2rem;
  height: 2rem;
`;

export const popperCls = css`
  position: relative;
  z-index: 2;

  /**
   * always pull the popper up close to the user button icon, also on mobile
   * where a subnavigation bar is added
   */
  top: calc(5.1rem - (var(${headerHeightProp}) - var(${subHeaderHeightProp})));

  float: right;

  box-sizing: border-box;
  min-width: 22rem;
  max-width: 100%;

  margin-right: -2rem;

  box-shadow: 0 0 8px ${colorPropMixin(colorProps.shadow, 0.2)};

  font-family: var(${proximaNovaProps.fontFamily});
  font-weight: var(${proximaNovaProps.medium});
  font-size: 1.6rem;

  &::before {
    content: "";

    position: absolute;
    top: -4px;
    right: 2.6rem;
    z-index: -10;

    display: block;
    width: 8px;
    height: 8px;

    background: ${colorPropMixin(colorProps.bg)};
    box-shadow: 0 0 2px 1px ${colorPropMixin(colorProps.shadow, 0.05)};
    transform: rotate(45deg);
  }
`;

export const popperContentCls = css`
  padding: 3rem;
  background: ${colorPropMixin(colorProps.bg)};
`;

export const overlayCls = css`
  position: fixed;
  inset: 0;
  padding: calc(var(${headerHeightProp}) - var(${subHeaderHeightProp}))
    var(${headerPaddingProp}) 0;

  &::before {
    content: "";

    position: absolute;
    inset: calc(var(${headerHeightProp})) 0 0 0;
    z-index: 1;

    background: ${colorPropMixin(colorProps.shadow, 0.25)};
  }

  &.enter {
    opacity: 0.001;

    & .${popperCls} {
      transform: translateY(-30px);
    }

    &-active {
      opacity: 1;
      transition: opacity ${overlayTransitionDurationMs * 0.8}ms linear;

      & .${popperCls} {
        transform: translateY(0);
        transition: transform ${overlayTransitionDurationMs}ms
          var(${easingProps.outCubic});
      }
    }
  }

  &.exit {
    opacity: 1;
    pointer-events: none;

    &-active {
      opacity: 0.001;
      transition: opacity ${overlayTransitionDurationMs * 0.8}ms linear;
    }
  }
`;

export const logoutLinkCls = css`
  ${anchorReset};

  display: inline-flex;
  align-items: center;

  margin-top: 1.4rem;

  & > svg {
    height: 1.6rem;
    margin-right: 1rem;
  }

  ${hoverColorMixin(colorPropMixin(colorProps.primary))};
  ${outlineMixin({
    offset: {
      y: "0.5rem",
      x: "0.8rem",
    },
  })};
`;
