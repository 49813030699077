import { WPFooterColumn } from "../../models/WPFooterSection";
import { DAYS_TO_MS, HOURS_TO_MS } from "../constants/time";
import { createCache } from "../createCache";

import { ApiClientOptions } from "./typings/ApiClientOptions";
import { ApiRequestInterceptor } from "./typings/ApiRequestInterceptor";
import { fetchRequest } from "./utils/fetchRequest";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function makeFetchFooter(
  requestInterceptor: ApiRequestInterceptor,
  options: ApiClientOptions,
) {
  const cache = createCache<WPFooterColumn[]>({
    timeToLiveMs: 1 * HOURS_TO_MS,
    timeToEvictMs: 1 * DAYS_TO_MS,
    ...options,
  });

  return () =>
    cache("footer", async () => {
      const response = await fetchRequest<{
        sections: WPFooterColumn[];
      }>(requestInterceptor(`${process.env.WP_PORTAL_API}/footer`));

      return response.sections;
    });
}
