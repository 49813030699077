import type { Instance as PopperInstance } from "@popperjs/core/lib";
import { useEffect, useRef } from "react";

import type { createPopper } from "./createPopper";

const POPPER_OFFSET_PX = 12;
const ARROW_PADDING_PX = 12;
const WINDOW_MARGIN_PX = 15;

type Self = {
  popper?: PopperInstance;
  destroyTimeout?: number;
};

export type PopperPlacement = "left" | "right" | "top" | "bottom";

/**
 * position the container using Popper.js to handle the styling in the
 * background
 */
export function usePopper({
  containerRef,
  popperRef,
  arrowRef,
  placement,
  active,
  transitionDurationMs,
  offsetPx = 0,
  marginPx = 0,
  content,
}: {
  containerRef: React.RefObject<HTMLElement | SVGElement>;
  popperRef: React.RefObject<HTMLElement>;
  arrowRef: React.RefObject<HTMLElement>;
  placement: PopperPlacement;
  active: boolean;
  transitionDurationMs: number;
  offsetPx?: number;
  marginPx?: number;
  content: React.ReactNode[];
}): void {
  const self = useRef<Self>({}).current;

  // use an effect to react to tooltip activation and position it using
  // Popper.js
  useEffect(() => {
    const container = containerRef.current;
    const popper = popperRef.current;

    if (!container || !popper || !active) {
      return;
    }

    if (createPopperCb === undefined) {
      // as we've imported popper.js dynamically, there will be a short amount
      // of time where the library is unavailable...
      //
      // tooltips are non-essential so during warmup of the application, we
      // accept that there are rare cases where a tooltip wouldn't be displayed
      return;
    }

    if (self.destroyTimeout !== undefined) {
      window.clearTimeout(self.destroyTimeout);
      self.destroyTimeout = undefined;
    }

    if (self.popper === undefined) {
      self.popper = createPopperCb(container, popper, {
        placement,
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              padding: WINDOW_MARGIN_PX + marginPx,
            },
          },
          {
            name: "offset",
            options: {
              offset: [0, POPPER_OFFSET_PX + offsetPx],
            },
          },
          {
            name: "computeStyles",
            options: {
              adaptive: false,
            },
          },
          {
            name: "arrow",
            options: {
              element: arrowRef.current,
              padding: ARROW_PADDING_PX,
            },
          },
        ],
      });
    }

    return (): void => {
      // we cannot instantly destroy the popper once it becomes inactive, as we
      // perform a fade out transition... therefore we need to defer the call
      // to popper.destroy until it has faded out completely
      self.destroyTimeout = window.setTimeout(() => {
        self.popper?.destroy();

        self.popper = undefined;
        self.destroyTimeout = undefined;
      }, transitionDurationMs + 5);
    };
  }, [
    containerRef,
    popperRef,
    arrowRef,
    self,
    active,
    transitionDurationMs,
    placement,
    offsetPx,
    marginPx,
  ]);

  useEffect(
    () => {
      // update position of popper whenever label changes
      self.popper?.update();
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [self, ...content],
  );
}

// fetch create popper (along with the entire popper.js library) in the
// background, so that it doesn't interfere with initial bootstrapping
// performance
let createPopperCb: typeof createPopper | undefined;

import("./createPopper").then(({ createPopper }) => {
  createPopperCb = createPopper;
});
