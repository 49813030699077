import { cx } from "@linaria/core";
import React, { useCallback, useState } from "react";
import { CSSTransition } from "react-transition-group";

import {
  WPAsyncPageGroupDescriptor,
  WPPageDescriptor,
  WPPageGroupDescriptor,
} from "../../../../models/WPPageDescriptor";
import { isPageDescriptor } from "../../../../utils/guards/isPageDescriptor";
import { useActivePagePath } from "../../hooks/useActivePagePath";
import { AppPage } from "../../hooks/usePageFromRouter";
import { LinkGroup } from "../SubNavigation/LinkGroup";
import { MobileLinkItem } from "../SubNavigation/MobileLinkItem";

import * as styles from "./styles/SubHeaderNav";

type Props = {
  page: AppPage | undefined;
  toggled: boolean;

  onClose(): void;
};

export function SubHeaderNav({
  page,
  toggled,
  onClose,
}: Props): JSX.Element | null {
  if (!page?.isAuthorized || !page.subNavigation) {
    return null;
  }

  return (
    <CSSTransition
      in={toggled}
      timeout={styles.overlayTransitionDurationMs}
      mountOnEnter
      unmountOnExit
    >
      <div className={styles.overlayCls} onClick={onClose}>
        <div
          className={cx(
            styles.popperCls,
            page?.product && "withProductStyling",
          )}
          onClick={(evt) => {
            evt.stopPropagation();
          }}
        >
          <ul className={styles.listCls}>
            {page.subNavigation.map((it) => (
              <SubNavigationItem key={it.path} model={it} onClose={onClose} />
            ))}
          </ul>
        </div>
      </div>
    </CSSTransition>
  );
}

type ItemProps = {
  model: WPPageDescriptor | WPPageGroupDescriptor | WPAsyncPageGroupDescriptor;
  onClose(): void;
};

function SubNavigationItem({ model, onClose }: ItemProps): JSX.Element {
  const activePagePath = useActivePagePath();

  const [isExpandedGroup, setExpandedGroup] = useState<boolean>(
    activePagePath.includes(model.path),
  );

  // Links should behave differently when the SubNavigation is rendered as a
  // SubHeaderNav. In particular Group links should simply toggle its expanded
  // state and regular links should close the overlay on navigate.
  const handleLinkClick = useCallback(
    (evt: React.MouseEvent<HTMLAnchorElement>, isGroupLink?: boolean) => {
      if (isGroupLink) {
        evt.preventDefault();
        setExpandedGroup((prev) => !prev);
      } else {
        onClose();
      }
    },
    [onClose],
  );

  return (
    <li>
      {isPageDescriptor(model) ? (
        <MobileLinkItem onClick={handleLinkClick} item={model} />
      ) : (
        <LinkGroup
          forceExpanded={isExpandedGroup}
          item={model}
          onLinkClick={handleLinkClick}
          Link={MobileLinkItem}
        />
      )}
    </li>
  );
}
