import { cx } from "@linaria/core";

import * as styles from "./styles/svg";
import { normalizeStrokeWidth } from "./utils/normalizeStrokeWidth";

type Props = {
  className?: string;
  strokeWidth?: number;
  height?: string | number;
  color?: string;
};

export function LogoutIcon({
  className,
  height,
  strokeWidth = 2,
  color = "currentColor",
}: Props): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15.883 14.902"
      className={cx(styles.svgCls, className)}
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      height={height}
      strokeWidth={normalizeStrokeWidth(strokeWidth, 14.902)}
    >
      <path d="m5.006 7.454h10.277" />
      <path d="m12.836 9.901 2.447-2.448-2.447-2.446" />
      <path d="m10.39 10.387v2.936a.938.938 0 0 1 -.89.979h-8.008a.938.938 0 0 1 -.889-.979v-11.744a.937.937 0 0 1 .889-.979h8.008a.938.938 0 0 1 .89.979v2.936" />
    </svg>
  );
}
