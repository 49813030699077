import { useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { SSRProps } from "../../typings/window";
import { BreakpointProvider } from "../hooks/context/useBreakpoint";
import { ContentBoundaryWidthProvider } from "../hooks/context/useContentBoundaryWidth";
import { useLoaderState } from "../hooks/state/useLoaderState";

import { ActivePageProvider } from "./hooks/useActivePage";
import { useDocumentTitle } from "./hooks/useDocumentTitle";
import { useLoadIndicator } from "./hooks/useLoadIndicator";
import { usePageFromRouter } from "./hooks/usePageFromRouter";
import { Footer } from "./layout/Footer/Footer";
import { Header } from "./layout/Header/Header";
import { PageWrapper } from "./layout/PageWrapper";
import { SubNavigation } from "./layout/SubNavigation/SubNavigation";
import * as styles from "./styles/App";

type Props = {
  ssr: SSRProps;
};

export function App({ ssr }: Props): JSX.Element {
  const currPage = usePageFromRouter();
  const showInitialThrobber = useLoaderState(!currPage, {
    delayMs: 0,
    durationMs: 800,
    allowInitial: true,
  });

  useLoadIndicator(currPage);
  useDocumentTitle(currPage);
  useInitialThrobber(showInitialThrobber.current);

  return (
    <ActivePageProvider value={currPage}>
      <BreakpointProvider>
        <ContentBoundaryWidthProvider>
          <Header page={currPage} />

          <SubNavigation
            items={currPage?.subNavigation ?? []}
            isAuthorized={currPage?.isAuthorized ?? true}
          />

          <TransitionGroup component={null}>
            {currPage && !showInitialThrobber.current && (
              <CSSTransition
                key={currPage.path}
                timeout={{
                  enter: styles.fadeInTransitionDurationMs,
                  exit: 0,
                }}
              >
                <PageWrapper
                  className={styles.fadeInCls}
                  page={currPage}
                  withSubNavigation={!!currPage?.subNavigation?.length}
                />
              </CSSTransition>
            )}

            {currPage && !showInitialThrobber.current && (
              <CSSTransition
                key="footer"
                timeout={{
                  enter: styles.fadeInTransitionDurationMs,
                  exit: 0,
                }}
              >
                <Footer
                  className={styles.fadeInCls}
                  columns={ssr.footerColumns}
                />
              </CSSTransition>
            )}
          </TransitionGroup>
        </ContentBoundaryWidthProvider>
      </BreakpointProvider>
    </ActivePageProvider>
  );
}

/**
 * Manages the native initial throbber, ensuring it is removed once the page has
 * been loaded.
 */
function useInitialThrobber(showInitialThrobber: boolean) {
  useEffect(() => {
    // Ensure we remove the throbber once it is not needed anymore
    if (!showInitialThrobber) {
      const throbber = document.querySelector(
        "#throbber",
      ) as HTMLElement | null;

      throbber?.classList.add(styles.throbberCls, "exit");
      throbber?.offsetWidth;
      throbber?.classList.add("exit-active");

      setTimeout(() => {
        throbber?.parentElement?.remove();
      }, styles.fadeInTransitionDurationMs);
    }
  }, [showInitialThrobber]);
}
