import { css } from "@linaria/core";

import { colorPropMixin } from "../../../../styles/mixins/colorProp";
import { colorProps, proximaNovaProps } from "../../../../styles/variables";

export const transitionDurationMs = 200;

export const tooltipCls = css`
  position: absolute;
  z-index: 200000;

  display: flex;
  width: max-content;
  max-width: 300px;

  box-shadow: 0 0 5px ${colorPropMixin(colorProps.shadow, 0.1)};

  pointer-events: none;

  /**
   * fade the tooltip in/out when entering/exiting
   */
  &-enter {
    opacity: 0.001;

    &-active {
      opacity: 1;
      transition: opacity ${transitionDurationMs}ms linear;
    }
  }

  &-exit {
    &-active {
      opacity: 0.001;
      transition: opacity ${transitionDurationMs}ms linear;
    }
  }

  /**
   * tooltips never make sense when printing the current document, so simply
   * hide them away.
   */
  @media print {
    display: none;
  }
`;

export const arrowCls = css`
  z-index: 0;

  display: block;
  width: 8px;
  height: 8px;

  /**
   * render the rotated arrow using a pseudo layer, as we cannot apply transform
   * directly on the layer itself (popper.js uses this property to align the
   * arrow towards the container).
   */
  &::before {
    content: "";

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background: rgb(var(${colorProps.bg}));
    box-shadow: 0 0 2px 1px rgba(var(${colorProps.shadow}), 0.03);
    transform: rotate(45deg);
  }

  /**
   * align arrow towards the container based on placement of the tooltip.
   */
  .placement--right & {
    left: -4px;
  }

  .placement--left & {
    right: -4px;
  }

  .placement--top & {
    bottom: -4px;
  }

  .placement--bottom & {
    top: -4px;
  }
`;

export const labelCls = css`
  display: block;
  position: relative;
  z-index: 1;

  padding: 0.6rem 1.2rem;
  background: rgb(var(${colorProps.bg}));

  font-size: 1.4rem;
  line-height: 1.2;
  font-family: var(${proximaNovaProps.fontFamily});
  font-weight: var(${proximaNovaProps.medium});
  text-align: center;
  color: rgb(var(${colorProps.text}));
`;

export const shortcutCls = css`
  display: inline-block;
  margin-left: 0.5em;

  /**
   * auto-wrap shortcut in parantheses using pseudo layers
   */
  &::before {
    content: "(";
  }

  &::after {
    content: ")";
  }
`;
