import { cx } from "@linaria/core";
import React, { useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { PopperPlacement, usePopper } from "../usePopper";

import { useActiveState } from "./hooks/useActiveState";
import * as styles from "./styles/Tooltip";

export type TooltipProps = {
  children: React.ReactElement;

  className?: string;
  label?: React.ReactNode | React.ReactNode[];
  shortcut?: string;

  placement: PopperPlacement;
  offsetPx?: number;

  /**
   * point to the DOM node which the tooltip describes.
   */
  containerRef: React.RefObject<HTMLElement | SVGElement>;

  /**
   * optionally point to the DOM node which should trigger the tooltip on hover
   * or touch.
   */
  togglerRef?: React.RefObject<HTMLElement | SVGElement>;

  /**
   * specifies a parent layer that the tooltip should be mounted into, which can
   * help prevent cropping due to overflow settings on default parent layers.
   */
  mountIn?: HTMLElement | null;

  /**
   * if enabled, then the tooltip will appear for the given amount of time after
   * the user taps it (or until he taps outside of it again)
   */
  showAfterTapMs?: number;

  /** forces tooltip to never be displayed */
  disabled?: boolean;

  /** forces tooltip to be displayed, even if the toggler ref isn't hovered */
  enabled?: boolean;
};

export const Tooltip: React.FC<TooltipProps> = ({
  mountIn,
  togglerRef,
  containerRef,
  className,
  label,
  shortcut,
  placement,
  offsetPx,
  disabled,
  enabled,
  showAfterTapMs,
  children,
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);

  const active = useActiveState({
    togglerRef,
    containerRef,
    disabled,
    enabled,
    showAfterTapMs,
  });

  usePopper({
    containerRef,
    popperRef: tooltipRef,
    arrowRef,
    placement,
    active,
    transitionDurationMs: styles.transitionDurationMs,
    offsetPx,
    content: [label, shortcut],
  });

  // keep a consistent aria-label applied to the toggler button
  useEffect(() => {
    if (typeof label !== "string") {
      // if complex content was given, we cannot safely apply it as an
      // aria-label without browser based processing which we're not interested
      // in doing at the current point
      return;
    }

    const toggler = togglerRef?.current ?? containerRef.current;

    if (!toggler || disabled) {
      return;
    }

    toggler.setAttribute("aria-label", label);
  }, [togglerRef, containerRef, label, disabled]);

  // if no tooltip was provided, then there's not really any point to applying
  // the tooltip within the markup...
  if (label === undefined && shortcut === undefined) {
    return <>{children}</>;
  }

  const tooltip = (
    <TransitionGroup component={null}>
      {active && (
        <CSSTransition
          timeout={styles.transitionDurationMs}
          classNames={styles.tooltipCls}
          mountOnEnter
          unmountOnExit
        >
          <div
            className={cx(
              className,
              styles.tooltipCls,
              `placement--${placement}`,
            )}
            ref={tooltipRef}
            role="tooltip"
          >
            <span className={styles.labelCls}>
              {label}
              {shortcut !== undefined && (
                <span className={styles.shortcutCls}>
                  {shortcut
                    .replace(/^cmd(\+|-)/i, "⌘")
                    .replace(/\+\+/, "+")
                    .replace(/-\+/, "+")
                    .replace(/\+-/, "-")}
                </span>
              )}
            </span>

            <i ref={arrowRef} className={styles.arrowCls} />
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  );

  // mount through a portal if a custom parent layer was provided
  if (mountIn != null) {
    return (
      <>
        {children}
        {createPortal(tooltip, mountIn)}
      </>
    );
  }

  return (
    <>
      {children}
      {tooltip}
    </>
  );
};
