import { cx } from "@linaria/core";
import { useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import * as styles from "./styles/BurgerButton";

type Props = {
  label?: string | undefined;

  toggled: boolean;
  onClick?(): void;
};

export function BurgerButton({ label, toggled, onClick }: Props): JSX.Element {
  const [isHovered, setHovered] = useState(false);

  return (
    <button
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={cx(
        styles.buttonCls,
        toggled && "toggled",
        isHovered && "hovered",
      )}
      onClick={onClick}
      aria-label="Åbn navigationsmenu"
    >
      <div className={styles.iconWrapperCls}>
        <div className={styles.iconCls} />
        <div className={styles.crossLinesCls} />
      </div>

      <TransitionGroup className={styles.labelWrapperCls}>
        <CSSTransition
          key={label}
          classNames={styles.labelCls}
          timeout={styles.LABEL_CROSSFADE_DURATION_MS}
        >
          <span className={styles.labelCls}>{label}</span>
        </CSSTransition>
      </TransitionGroup>
    </button>
  );
}
