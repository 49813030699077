export enum WPProductTypes {
  PRODUCT = "Product",
  LINK = "ProductLink",
}

export type WPProductConfig = {
  type: WPProductTypes.PRODUCT;
  id: string;
  path: string;
  isbn: string;
  title: string;
  grade: string;
  vignette: string;
  color: string;
  trialUrl: string;
};

export type WPProductLinkConfig = {
  type: WPProductTypes.LINK;
  id: string;
  isbn: string;
  title: string;
  grade: string;
  vignette: string;
  color: string;
  linkUrl: string;
};
