import nProgress from "nprogress";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useLoaderState } from "../../hooks/state/useLoaderState";
import { easingProps } from "../../styles/variables";
import * as styles from "../styles/nProgress";

import { AppPage } from "./usePageFromRouter";

export function useLoadIndicator(currPage: AppPage | undefined): void {
  const location = useLocation();

  const showLoader = useLoaderState(
    !!currPage && currPage.path !== location.pathname,
    {
      delayMs: 125,
      durationMs: 500,
    },
  ).current;

  useEffect(() => {
    if (showLoader) {
      nProgress.start();
    } else {
      nProgress.done();
    }
  }, [showLoader]);
}

nProgress.configure({
  showSpinner: false,
  easing: `var(${easingProps.inOutQuad})`,
  template: `<div class="${styles.barCls}" role="bar"><div class="${styles.pegCls}"></div></div>`,
});
