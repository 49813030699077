import { css } from "@linaria/core";

import { TABLET_BREAKPOINT_PX } from "../../../../styles/constants";
import { breakpointUpMixin } from "../../../../styles/mixins/breakpoints";
import { colorPropMixin } from "../../../../styles/mixins/colorProp";
import { listReset } from "../../../../styles/resets";
import {
  colorProps,
  easingProps,
  headerPaddingProp,
} from "../../../../styles/variables";

import { headerHeightProp } from "./Header";

export const overlayTransitionDurationMs = 175;

export const listCls = css`
  ${listReset};

  margin: 1.3rem 0;
`;

export const popperCls = css`
  position: relative;
  z-index: 2;

  max-height: calc(100vh - var(${headerHeightProp}) - 5rem);
  overflow-y: auto;
  overscroll-behavior: contain;

  box-sizing: border-box;
  width: 100%;
  padding: 0 var(${headerPaddingProp});

  box-shadow: 0 3px 6px ${colorPropMixin(colorProps.shadow, 0.1)};
  border-top: 0.1rem solid ${colorPropMixin(colorProps.text, 0.15)};

  background: ${colorPropMixin(colorProps.grey_50)};
  color: ${colorPropMixin(colorProps.text)};

  &.withProductStyling {
    background: ${colorPropMixin(colorProps.brand)};
    color: ${colorPropMixin(colorProps.textOnBrand)};
    border-top: 0.1rem solid ${colorPropMixin(colorProps.textOnBrand, 0.5)};
  }
`;

export const overlayCls = css`
  @media screen and (${breakpointUpMixin(TABLET_BREAKPOINT_PX)}) {
    display: none;
  }

  position: fixed;
  inset: 0;
  padding-top: var(${headerHeightProp});

  &::before {
    content: "";

    position: absolute;
    inset: var(${headerHeightProp}) 0 0 0;
    z-index: 1;

    background: ${colorPropMixin(colorProps.shadow, 0.25)};
  }

  &.enter {
    opacity: 0.001;

    & .${listCls} {
      transform: translateY(-1rem);
    }

    &-active {
      opacity: 1;
      transition: opacity ${overlayTransitionDurationMs}ms linear;

      & .${listCls} {
        transform: translateY(0);

        transition: transform ${overlayTransitionDurationMs}ms
          var(${easingProps.outQuad});
      }
    }
  }

  &.exit {
    opacity: 1;
    pointer-events: none;

    &-active {
      opacity: 0.001;
      transition: opacity ${overlayTransitionDurationMs * 0.8}ms linear;
    }
  }
`;
