import { cx } from "@linaria/core";

import * as styles from "./styles/svg";
import { normalizeStrokeWidth } from "./utils/normalizeStrokeWidth";

type Props = {
  className?: string;
  strokeWidth?: number;
  height?: string | number;
  color?: string;
};

export function KeyIcon({
  className,
  height,
  strokeWidth = 1.65,
  color = "currentColor",
}: Props): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21.707 21.879"
      className={cx(styles.svgCls, className)}
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      height={height}
      strokeWidth={normalizeStrokeWidth(strokeWidth, 21.879)}
    >
      <path d="m11.679 13.157 2.262-2.262 1.557.418a1.4 1.4 0 0 0 1.71-1.71l-.418-1.557.7-.7 1.557.418a1.4 1.4 0 0 0 1.71-1.71l-.417-1.557a2.095 2.095 0 1 0 -2.961-2.96l-8.659 8.659a5.585 5.585 0 1 0 2.959 2.961z" />
      <path d="m4.066 16.172a1.64 1.64 0 1 0 1.64-1.64 1.64 1.64 0 0 0 -1.64 1.64z" />
    </svg>
  );
}
