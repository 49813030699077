import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import {
  WPAsyncPageGroupDescriptor,
  WPPageDescriptor,
  WPPageGroupDescriptor,
} from "../../../../models/WPPageDescriptor";
import { fadeInTransitionDurationMs } from "../../styles/App";

import { LinkList } from "./LinkList";
import * as styles from "./styles/SubNavigation";

type Props = {
  items: (
    | WPPageGroupDescriptor
    | WPPageDescriptor
    | WPAsyncPageGroupDescriptor
  )[];
  isAuthorized: boolean;
};

export function SubNavigation({ items, isAuthorized }: Props): JSX.Element {
  return (
    <TransitionGroup component={null}>
      {(items.length > 0 || !isAuthorized) && (
        <CSSTransition timeout={fadeInTransitionDurationMs}>
          <aside className={styles.asideCls}>
            <TransitionGroup component={null}>
              <CSSTransition
                key={generateSubNavigationKey(items, isAuthorized)}
                timeout={100}
              >
                {isAuthorized ? (
                  <nav className={styles.navCls}>
                    <LinkList items={items} />
                  </nav>
                ) : (
                  <Skeleton />
                )}
              </CSSTransition>
            </TransitionGroup>
          </aside>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}

function Skeleton(): JSX.Element {
  return (
    <ul className={styles.skeletonCls}>
      <li className={styles.skeletonLineCls} />
      <li className={styles.skeletonLineCls} />
      <li className={styles.skeletonLineCls} />
      <li className={styles.skeletonLineCls} />
    </ul>
  );
}

function generateSubNavigationKey(
  items: (
    | WPPageGroupDescriptor
    | WPPageDescriptor
    | WPAsyncPageGroupDescriptor
  )[],
  isAuthorized: boolean,
): string {
  return isAuthorized ? items.map((item) => item.path).join() : "unauthorized";
}
