import { css } from "@linaria/core";

import { h1Style, paragraphStyle } from "../../../../styles/defaults";

export const headingCls = css`
  ${h1Style};
  font-size: 3rem;
`;

export const bodyCls = css`
  ${paragraphStyle}
`;
