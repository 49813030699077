import "./_effects/trackJS";
import "./_effects/stylesheet";
import "./_effects/containerReferences";

import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { App } from "./app/App";
import { UserProvider } from "./hooks/context/useUser";

const root = createRoot(window._wp.appContainer);

root.render(
  <UserProvider>
    <BrowserRouter>
      <App ssr={window._wp.ssr} />
    </BrowserRouter>
  </UserProvider>,
);
