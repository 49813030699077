import { User } from "../../models/User";
import { MaybePromise } from "../../typings/MaybePromise";
import { DAYS_TO_MS, HOURS_TO_MS } from "../constants/time";
import { createCache } from "../createCache";

import { ApiClientOptions } from "./typings/ApiClientOptions";
import { ApiRequestInterceptor } from "./typings/ApiRequestInterceptor";
import { fetchRequest } from "./utils/fetchRequest";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function makeIsAuthorized(
  requestInterceptor: ApiRequestInterceptor,
  options: ApiClientOptions,
) {
  const cache = createCache<Partial<Record<string, boolean>>>({
    timeToLiveMs: 1 * HOURS_TO_MS,
    timeToEvictMs: 1 * DAYS_TO_MS,
    ...options,
  });

  return <TIds extends string>(
    user: User | undefined,
    productIsbns: TIds[],
  ) => {
    return cache(`${user?.id}:${productIsbns.join()}`, async () => {
      if (!user) {
        return {};
      }

      const response = await fetchRequest<
        | undefined
        | {
            Products?: { ProductId: TIds; IsAuthorized: boolean }[];
          }
      >(
        requestInterceptor(
          `${process.env.LOGIN_CONNECTOR_API}/api/ValidateSubscriptions/ValidateSubscription`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify({
              ClientWebShopName: "gu-unic",
              ProductIds: [...new Set(productIsbns.filter((it) => !!it))],
              SubscriptionAuthentToken: user.loginConnectorToken,
            }),
          },
        ),
      );

      const result: Partial<Record<TIds, boolean>> =
        response?.Products?.reduce(
          (acc, product) => ({
            ...acc,
            [product.ProductId]: product.IsAuthorized,
          }),
          {},
        ) ?? {};

      return result;
    }) as MaybePromise<Partial<Record<TIds, boolean>>>;
  };
}
