import { css } from "@linaria/core";

export const fadeInTransitionDurationMs = 200;

export const fadeInCls = css`
  &.enter {
    opacity: 0.001;

    &-active {
      opacity: 1;
      transition: opacity ${fadeInTransitionDurationMs}ms linear;
    }
  }

  &.exit {
    display: none;
  }
`;

export const throbberCls = css`
  &.exit {
    opacity: 1;

    &-active {
      opacity: 0.001;
      transition: opacity ${fadeInTransitionDurationMs}ms linear;
    }
  }
`;
