import { CSSTransition } from "react-transition-group";

import { WPNavigationLink } from "../../../../../models/WPNavigationLink";
import {
  WPPageDescriptor,
  WPPageGroupDescriptor,
} from "../../../../../models/WPPageDescriptor";
import { BurgerButton } from "../../../../components/atoms/BurgerButton/BugerButton";
import { useOverlay } from "../../../../hooks/effect/useOverlay";
import { useActivePagePath } from "../../../hooks/useActivePagePath";
import { AppPage } from "../../../hooks/usePageFromRouter";

import { NavigationLink } from "./NavigationLink";
import * as styles from "./styles/MobileNavigation";

type Props = {
  page: AppPage | undefined;
  navigation:
    | (WPPageDescriptor | WPPageGroupDescriptor | WPNavigationLink)[]
    | undefined;
};

export function MobileNavigation({
  page,
  navigation,
}: Props): JSX.Element | null {
  const overlay = useOverlay();

  const activePath = useActivePagePath();

  const activePage = page?.product
    ? navigation?.[0]
    : page?.parentPageDescriptor ?? page?.pageDescriptor;

  return (
    <div className={styles.boundaryCls}>
      <div className={styles.wrapperCls}>
        <BurgerButton
          label={activePage?.title}
          toggled={overlay.isOpen}
          onClick={overlay.toggle}
        />
      </div>

      <CSSTransition
        in={overlay.isOpen}
        timeout={styles.overlayTransitionDurationMs}
        mountOnEnter
        unmountOnExit
      >
        <div className={styles.overlayCls} onClick={overlay.close}>
          <div
            className={styles.popperCls}
            onClick={(evt) => {
              evt.stopPropagation();
            }}
          >
            <ul className={styles.linkListCls}>
              {navigation?.map((it, index) => (
                <li key={index}>
                  <NavigationLink
                    activePath={activePath}
                    className={styles.linkCls}
                    model={it}
                    onClick={overlay.close}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}
