import { css } from "@linaria/core";

import { TABLET_BREAKPOINT_PX } from "../../../../styles/constants";
import { breakpointDownMixin } from "../../../../styles/mixins/breakpoints";
import { listReset } from "../../../../styles/resets";

import { itemCls } from "./LinkList";

export const subGroupTransitionDurationMs = 175;

export const subGroupCls = css`
  ${listReset};
  margin-top: 1rem;

  & .${itemCls} {
    padding-left: 3rem;
  }

  @media screen and (${breakpointDownMixin(TABLET_BREAKPOINT_PX)}) {
    margin: 0;
  }

  &.enter {
    /** Avoid displaying scrollbar in Firefox when transition is running -
     it should not even be there in the first case! */
    scrollbar-width: none;

    & .${itemCls} {
      opacity: 0.001;
    }
  }

  &.enter-active .${itemCls} {
    opacity: 1;
    transition: opacity ${subGroupTransitionDurationMs * 0.65}ms linear;
  }

  &.exit {
    /** Avoid displaying scrollbar in Firefox when transition is running -
     it should not even be there in the first case! */
    scrollbar-width: none;

    & .${itemCls} {
      opacity: 1;
    }
  }

  &.exit-active .${itemCls} {
    opacity: 0.001;
    transition: opacity ${subGroupTransitionDurationMs * 0.65}ms linear
      ${subGroupTransitionDurationMs * 0.35}ms;
  }
`;
