import { css } from "@linaria/core";

import { TABLET_BREAKPOINT_PX } from "../../../../../styles/constants";
import { breakpointUpMixin } from "../../../../../styles/mixins/breakpoints";
import { colorPropMixin } from "../../../../../styles/mixins/colorProp";
import { outlineMixin } from "../../../../../styles/mixins/outline";
import { anchorReset, listReset } from "../../../../../styles/resets";
import {
  colorProps,
  easingProps,
  headerPaddingProp,
  proximaNovaProps,
} from "../../../../../styles/variables";
import { headerHeightProp } from "../../styles/Header";
import { subHeaderHeightProp } from "../../styles/SubHeader";

export const overlayTransitionDurationMs = 175;

export const boundaryCls = css`
  position: relative;
  z-index: 1;

  padding: 0 var(${headerPaddingProp});
`;

export const wrapperCls = css`
  height: 5rem;
  width: 100%;

  @media screen and (${breakpointUpMixin(TABLET_BREAKPOINT_PX)}) {
    display: none;
  }
`;

export const popperCls = css`
  position: relative;
  z-index: 2;

  max-height: calc(100vh - var(${headerHeightProp}) - 5rem);
  overflow-y: auto;
  overscroll-behavior: contain;

  box-sizing: border-box;
  width: 100%;
  padding: 0 var(${headerPaddingProp});

  background: ${colorPropMixin(colorProps.bg)};
  box-shadow: 0 3px 6px ${colorPropMixin(colorProps.shadow, 0.1)};
  border-top: 0.1rem solid ${colorPropMixin(colorProps.grey_400, 0.3)};
`;

export const linkListCls = css`
  ${listReset};

  padding: 1.7rem 0;
`;

export const linkCls = css`
  ${anchorReset};
  ${outlineMixin({ offset: { x: "0.7rem", y: "-1rem" } })};

  display: flex;
  align-items: center;
  height: 100%;

  padding: 1.3rem 0;

  font-family: var(${proximaNovaProps.fontFamily});
  font-weight: var(${proximaNovaProps.regular});
  font-size: 1.6rem;
  line-height: 1.43;
  text-transform: uppercase;

  color: ${colorPropMixin(colorProps.text)};
  transition: color 0.1s linear;

  &:hover {
    color: ${colorPropMixin(colorProps.brand)};
  }

  &[aria-current="page"],
  &[aria-current="location"] {
    font-weight: var(${proximaNovaProps.bold});
  }
`;

export const overlayCls = css`
  position: fixed;
  inset: 0;
  padding-top: calc(var(${headerHeightProp}) - var(${subHeaderHeightProp}));

  &::before {
    content: "";

    position: absolute;
    inset: calc(var(${headerHeightProp}) - var(${subHeaderHeightProp})) 0 0 0;
    z-index: 1;

    background: ${colorPropMixin(colorProps.shadow, 0.25)};
  }

  &.enter {
    opacity: 0.001;

    & .${linkListCls} {
      transform: translateY(-1rem);
    }

    &-active {
      opacity: 1;
      transition: opacity ${overlayTransitionDurationMs}ms linear;

      & .${linkListCls} {
        transform: translateY(0);

        transition: transform ${overlayTransitionDurationMs}ms
          var(${easingProps.outQuad});
      }
    }
  }

  &.exit {
    opacity: 1;
    pointer-events: none;

    &-active {
      opacity: 0.001;
      transition: opacity ${overlayTransitionDurationMs * 0.8}ms linear;
    }
  }
`;
