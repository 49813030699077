export function slugify(input: string): string {
  let output = input
    .toLowerCase()
    .replace(/\s/g, "-")
    .replace(/æ/g, "ae")
    .replace(/ø/g, "oe")
    .replace(/å/g, "aa")
    .replace(/[^a-z0-9]/g, "-");

  while (output.includes("--")) {
    output = output.replaceAll("--", "-");
  }

  return output.replace(/^-/, "").replace(/-$/, "");
}
