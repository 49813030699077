import { css } from "@linaria/core";

import { TABLET_BREAKPOINT_PX } from "../../../../../styles/constants";
import { breakpointDownMixin } from "../../../../../styles/mixins/breakpoints";
import { outlineMixin } from "../../../../../styles/mixins/outline";
import { anchorReset, listReset } from "../../../../../styles/resets";
import { proximaNovaProps } from "../../../../../styles/variables";
import { rightSectionCls } from "../../styles/Header";

export const navigationTransitionDurationMs = 150;

export const navigationCls = css`
  display: flex;
  align-items: center;
  height: 100%;

  &.enter {
    opacity: 0.001;

    &-active {
      opacity: 1;
      transition: opacity ${navigationTransitionDurationMs}ms linear;
    }
  }

  &.exit {
    opacity: 1;

    &-active {
      opacity: 0.001;
      transition: opacity ${navigationTransitionDurationMs}ms linear;
    }
  }

  @media screen and (${breakpointDownMixin(TABLET_BREAKPOINT_PX)}) {
    display: none;
  }
`;

export const underlineCls = css`
  height: 100%;
`;

export const linkListCls = css`
  ${listReset};

  display: flex;
  height: 100%;
`;

export const linkCls = css`
  ${anchorReset};

  display: flex;
  align-items: center;
  height: 100%;

  margin-right: 5rem;

  @media screen and (${breakpointDownMixin(1100)}) {
    margin-right: 3rem;
  }

  font-family: var(${proximaNovaProps.fontFamily});
  font-weight: var(${proximaNovaProps.regular});
  font-size: 1.6rem;
  line-height: 1;
  white-space: nowrap;
  text-transform: uppercase;

  transition: opacity 0.1s linear;

  .${rightSectionCls}:hover & {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  ${outlineMixin({ offset: { x: "0.7rem", y: "-1rem" } })};
`;
