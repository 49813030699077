import { css } from "@linaria/core";

import { MOBILE_BREAKPOINT_PX, TABLET_BREAKPOINT_PX } from "./constants";
import { breakpointDownMixin } from "./mixins/breakpoints";
import { resetAnchorColorProp } from "./resets";

const colorPrefix = "--color";
export const colorProps = {
  bg: `${colorPrefix}--bg`,
  shadow: `${colorPrefix}--shadow`,
  grey_50: `${colorPrefix}--grey--50`,
  grey_100: `${colorPrefix}--grey--100`,
  grey_400: `${colorPrefix}--grey--400`,
  text: `${colorPrefix}--text`,
  textInversed: `${colorPrefix}--text-inversed`,
  primary: `${colorPrefix}--primary`,
  brand: `${colorPrefix}--brand`,
  textOnBrand: `${colorPrefix}--text--on-brand`,
};

const proximaNovaPrefix = "--proxima-nova";
export const proximaNovaProps = {
  fontFamily: `${proximaNovaPrefix}--family`,
  regular: `${proximaNovaPrefix}--font-weight--regular`,
  medium: `${proximaNovaPrefix}--font-weight--medium`,
  bold: `${proximaNovaPrefix}--font-weight--bold`,
};

const easingPrefix = "--ease";
export const easingProps = {
  inQuad: `${easingPrefix}--in-quad`,
  outQuad: `${easingPrefix}--out-quad`,
  inOutQuad: `${easingPrefix}--in-out-quad`,
  inCubic: `${easingPrefix}--in-cubic`,
  outCubic: `${easingPrefix}--out-cubic`,
  inOutCubic: `${easingPrefix}--in-out-cubic`,
  inQuart: `${easingPrefix}--in-quart`,
  outQuart: `${easingPrefix}--out-quart`,
  inOutQuart: `${easingPrefix}--in-out-quart`,
  inOutQuint: `${easingPrefix}--in-out-quint`,
  inSine: `${easingPrefix}--in-sine`,
  outSine: `${easingPrefix}--out-sine`,
  elastic: `${easingPrefix}--elastic`,
};

export const boundaryPaddingProp = "--boundary--padding";
export const headerPaddingProp = "--header--padding";
export const blockMarginProp = "--block--margin";

css`
  :global(:root) {
    ${colorProps.bg}: 255, 255, 255;
    ${colorProps.shadow}: 0, 0, 0;
    ${colorProps.grey_50}: 247, 247, 247;
    ${colorProps.grey_100}: 232, 232, 232;
    ${colorProps.grey_400}: 112, 112, 112;
    ${colorProps.text}: 34, 34, 34;
    ${colorProps.textInversed}: var(${colorProps.bg});
    ${colorProps.primary}: 11, 88, 216;
    ${colorProps.brand}: var(${colorProps.primary});
    ${colorProps.textOnBrand}: var(${colorProps.text});

    ${proximaNovaProps.fontFamily}: proxima-nova, helvetica, sans-serif;
    ${proximaNovaProps.regular}: 400;
    ${proximaNovaProps.medium}: 600;
    ${proximaNovaProps.bold}: 700;

    ${easingProps.inQuad}: cubic-bezier(0.11, 0, 0.5, 0);
    ${easingProps.outQuad}: cubic-bezier(0.5, 1, 0.89, 1);
    ${easingProps.inOutQuad}: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    ${easingProps.inCubic}: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    ${easingProps.outCubic}: cubic-bezier(0.33, 1, 0.68, 1);
    ${easingProps.inOutCubic}: cubic-bezier(0.645, 0.045, 0.355, 1);
    ${easingProps.inQuart}: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    ${easingProps.outQuart}: cubic-bezier(0.165, 0.84, 0.44, 1);
    ${easingProps.inOutQuart}: cubic-bezier(0.77, 0, 0.175, 1);
    ${easingProps.inOutQuint}: cubic-bezier(0.86, 0, 0.07, 1);
    ${easingProps.inSine}: cubic-bezier(0.47, 0, 0.745, 0.715);
    ${easingProps.outSine}: cubic-bezier(0.39, 0.575, 0.565, 1);
    ${easingProps.elastic}: cubic-bezier(0.4, 1.01, 0.32, 1.05);

    ${resetAnchorColorProp}: var(${colorProps.text});
    ${headerPaddingProp}: 6rem;
    ${boundaryPaddingProp}: 4.5rem;
    ${blockMarginProp}: 6rem;

    @media screen and (${breakpointDownMixin(TABLET_BREAKPOINT_PX)}) {
      ${boundaryPaddingProp}: 3rem;
    }

    @media screen and (${breakpointDownMixin(MOBILE_BREAKPOINT_PX)}) {
      ${headerPaddingProp}: 30px;
      ${boundaryPaddingProp}: 30px;
      ${blockMarginProp}: 4.5rem;
    }
  }
`;
