import { useEffect, useState } from "react";

import { User } from "../../../../../../models/User";
import { WPNavigationLink } from "../../../../../../models/WPNavigationLink";
import {
  WPPageDescriptor,
  WPPageGroupDescriptor,
} from "../../../../../../models/WPPageDescriptor";
import { useApiClient } from "../../../../../hooks/context/useApiClient";

export function useNavigation(
  user: User | undefined | null,
): (WPPageDescriptor | WPPageGroupDescriptor | WPNavigationLink)[] | undefined {
  const apiClient = useApiClient();
  const [navigation, setNavigation] = useState<
    (WPPageDescriptor | WPPageGroupDescriptor | WPNavigationLink)[] | undefined
  >(undefined);

  useEffect(() => {
    if (user === null) {
      // wait until single sign on has been completed, before trying to fetch
      // navigation
      return;
    }

    Promise.resolve(apiClient.fetchNavigation(user)).then((navigation) => {
      setNavigation(navigation.filter((it) => !!it.title));
    });
  }, [apiClient, user]);

  return navigation;
}
