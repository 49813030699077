import { TrackJS } from "trackjs";

if (
  process.env.NODE_ENV === "production" &&
  process.env.APP_ENV === "production"
) {
  TrackJS.install({
    application: "gyldendal-webproever",
    token: "d9ef9b3a6fd24cc0a8b196990bd2698e",
  });
}
