import { css } from "@linaria/core";

import { colorProps } from "../../styles/variables";

export const barCls = css`
  position: fixed;
  z-index: 1000031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: rgb(var(${colorProps.primary}));
  pointer-events: none;
`;

export const pegCls = css`
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow:
    0 0 10px rgb(var(${colorProps.primary})),
    0 0 5px rgb(var(${colorProps.primary}));
  opacity: 1;
  transform: rotate(3deg) translate(0, -4px);
  pointer-events: none;
`;
