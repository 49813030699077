import { css } from "@linaria/core";

import { MOBILE_BREAKPOINT_PX } from "../../../../styles/constants";
import { breakpointDownMixin } from "../../../../styles/mixins/breakpoints";
import {
  marginBottomMixin,
  marginRightMixin,
  verticalMarginMixin,
} from "../../../../styles/mixins/collapsableMargin";
import { listReset } from "../../../../styles/resets";

export const listCls = css`
  ${listReset};

  display: flex;
  flex-wrap: wrap;

  ${verticalMarginMixin("3rem")};
`;

export const itemCls = css`
  ${marginRightMixin("3rem")};

  @media screen and (${breakpointDownMixin(MOBILE_BREAKPOINT_PX)}) {
    ${marginRightMixin("1.5rem")};
    ${marginBottomMixin("1.5rem")};
  }
`;
