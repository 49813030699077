import React from "react";

import { ErrorBoundaryMessage } from "./ErrorBoundaryMessage";

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.PureComponent<
  {
    children: React.ReactNode;
  },
  State
> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): Partial<State> {
    return { hasError: true };
  }

  public componentDidCatch(error: unknown, errorInfo: unknown): void {
    console.error(error, errorInfo);
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      return <ErrorBoundaryMessage />;
    }

    return this.props.children;
  }
}
