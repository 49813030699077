import { User } from "../../models/User";
import {
  WPAsyncPageGroupDescriptor,
  WPPageDescriptor,
  WPPageGroupDescriptor,
} from "../../models/WPPageDescriptor";
import { WPProductConfig } from "../../models/WPProductConfig";
import { WPTestGroup } from "../../models/WPTestGroup";
import { MaybePromise } from "../../typings/MaybePromise";
import { isPageDescriptor } from "../../utils/guards/isPageDescriptor";
import { DAYS_TO_MS, HOURS_TO_MS } from "../constants/time";
import { createCache } from "../createCache";
import { slugify } from "../slugify";

import { ApiClientOptions } from "./typings/ApiClientOptions";
import { ApiRequestInterceptor } from "./typings/ApiRequestInterceptor";
import { fetchRequest } from "./utils/fetchRequest";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function makeFetchProductNavigation(
  requestInterceptor: ApiRequestInterceptor,
  options: ApiClientOptions,
  methods: {
    fetchPracticeTests: (
      user: User,
      product: WPProductConfig,
    ) => MaybePromise<WPTestGroup[]>;
    fetchTeacherAvailableTests: (
      user: User,
      product: WPProductConfig,
    ) => MaybePromise<WPTestGroup[]>;
  },
) {
  const cache = createCache<
    (WPPageDescriptor | WPPageGroupDescriptor | WPAsyncPageGroupDescriptor)[]
  >({
    timeToLiveMs: 1 * HOURS_TO_MS,
    timeToEvictMs: 1 * DAYS_TO_MS,
    ...options,
  });

  return (user: User | undefined, product: WPProductConfig) => {
    const userType = user?.type ?? "guest";

    return cache(`${userType}:${product.id}`, async () => {
      const response = await fetchRequest<{
        pages: ((WPPageDescriptor | WPPageGroupDescriptor) & {
          hasAvailableTestsTeacher?: boolean;
          hasPracticeTests?: boolean;
        })[];
      }>(
        requestInterceptor(
          `${process.env.WP_PORTAL_API}/products/${product.id}/navigation/user-role/${userType}`,
        ),
      );

      // intercept links to pages including available tests for teacher and
      // practice tests, as they need to auto-inject subnavigation based on the
      // list of available levels within Avenx
      const pages = response.pages.map(
        ({ hasAvailableTestsTeacher, hasPracticeTests, ...descriptor }) => {
          if (hasAvailableTestsTeacher || hasPracticeTests) {
            return {
              id: descriptor.id,
              title: descriptor.title,
              path: descriptor.path,

              getPages: () => {
                if (!user) {
                  return [];
                }

                const response = hasPracticeTests
                  ? methods.fetchPracticeTests(user, product)
                  : methods.fetchTeacherAvailableTests(user, product);

                if (response instanceof Promise) {
                  return response.then((levels) =>
                    levels.map((level) => ({
                      id: descriptor.id,
                      path: `${descriptor.path}/${slugify(level.navn)}`,
                      title: level.navn,
                    })),
                  );
                }

                return response.map((level) => ({
                  id: descriptor.id,
                  path: `${descriptor.path}/${slugify(level.navn)}`,
                  title: level.navn,
                }));
              },
            };
          }

          return descriptor;
        },
      );

      // the first item in the response is always the main page, overrule the
      // path in case SiteCore isn't aware of this
      const firstPage = pages.find(isPageDescriptor);

      if (firstPage) {
        firstPage.path = `${product.path}/`;
      }

      return pages;
    });
  };
}
