import {
  WPProductConfig,
  WPProductLinkConfig,
} from "../../models/WPProductConfig";
import { DAYS_TO_MS, HOURS_TO_MS } from "../constants/time";
import { createCache } from "../createCache";

import { ApiClientOptions } from "./typings/ApiClientOptions";
import { ApiRequestInterceptor } from "./typings/ApiRequestInterceptor";
import { fetchRequest } from "./utils/fetchRequest";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function makeFetchProducts(
  requestInterceptor: ApiRequestInterceptor,
  options: ApiClientOptions,
) {
  const cache = createCache<(WPProductConfig | WPProductLinkConfig)[]>({
    timeToLiveMs: 1 * HOURS_TO_MS,
    timeToEvictMs: 1 * DAYS_TO_MS,
    ...options,
  });

  return () =>
    cache("products", async () => {
      const response = await fetchRequest<{
        products: (WPProductConfig | WPProductLinkConfig)[];
      }>(requestInterceptor(`${process.env.WP_PORTAL_API}/products`));

      return response.products.map((product) => ({
        ...product,
        vignette: `${process.env.WP_MEDIA_PATH}${product.vignette}`,
      }));
    });
}
