import { WPAccordion } from "./WPAccordion";
import { WPCallToAction } from "./WPCallToAction";
import { WPResource } from "./WPResource";

export enum WPContentBlockTypes {
  API_CALL = "ApiCall",
  TEXT_ELEMENT = "TextElement",
  IMAGE_ELEMENT = "ImageElement",
  VIDEO_ELEMENT = "VideoElement",
  PRODUCT_BANNER = "ProductBanner",
  RESOURCE_COLLECTION = "ResourceCollection",
  ACCORDION_COLLECTION = "AccordionCollection",
  TEXTBOX = "Textbox",
}

export enum WPApiActions {
  ALL_PRODUCTS = "AllProducts",
  TEACHER_AVAILABLE_PRODUCTS = "AvailableProductsTeacher",
  TEACHER_UNAVAILABLE_PRODUCTS = "UnavailableProductsTeacher",
  TEACHER_AVAILABLE_TESTS = "AvailableTestsTeacher",
  STUDENT_AVAILABLE_PRODUCTS = "AvailableProductsStudent",
  STUDENT_ACTIVE_TESTS = "ActiveTestsStudent",
  STUDENT_COMPLETED_TESTS = "CompletedTestsStudent",
  PRACTICE_TESTS = "PracticeTests",
}

export type WPApiBlock = {
  type: WPContentBlockTypes.API_CALL;
  action: WPApiActions;
  emptyResponseHeading: string;
  emptyResponseText: string;
  nonEmptyResponseHeading: string;
  nonEmptyResponseText: string;
};

export type WPTextBox = {
  type: WPContentBlockTypes.TEXTBOX;
  heading: string;
  body: string;
  callToActions?: WPCallToAction[];

  /**
   * if given the Textbox should enter the "hero"-mode with a solid background
   * the stretches to fill out the viewport
   */
  heroImage?: string;

  /**
   * this is an unfortunate name; when enabled, this textbox should ONLY be
   * displayed if the user doesn't have product access - in other words it
   * actually means "hide if user has product access"
   */
  showWhenNoProductAccess?: boolean;
};

export type WPProductBanner = {
  type: WPContentBlockTypes.PRODUCT_BANNER;
  title: string;
  grade: string;
  vignette: string;
};

export type WPTextElement = {
  type: WPContentBlockTypes.TEXT_ELEMENT;
  text: string;
};

export type WPImageElement = {
  type: WPContentBlockTypes.IMAGE_ELEMENT;
  copyright: string;
  description: string;
  source: string;
  title: string;

  height: number;
  width: number;
};

export type WPVideoElement = {
  type: WPContentBlockTypes.VIDEO_ELEMENT;
  copyright: string;
  description: string;
  title: string;
  mediaLibraryReferenceId: string;
  twentyThreeVideo?: {
    id: string;
    token: string;
    originalWidth?: string;
    originalHeight?: string;
  };
};

export type WPResourceCollection = {
  type: WPContentBlockTypes.RESOURCE_COLLECTION;
  title: string;
  items: WPResource[];
};

export type WPAccordionCollection = {
  type: WPContentBlockTypes.ACCORDION_COLLECTION;
  title: string;
  accordions: WPAccordion[];
};

export type WPContentBlock =
  | WPApiBlock
  | WPTextBox
  | WPProductBanner
  | WPResourceCollection
  | WPTextElement
  | WPImageElement
  | WPVideoElement
  | WPAccordionCollection;
