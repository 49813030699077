import { WPNavigationLink } from "../../models/WPNavigationLink";
import {
  WPAsyncPageGroupDescriptor,
  WPPageDescriptor,
  WPPageGroupDescriptor,
} from "../../models/WPPageDescriptor";

export function isAsyncPageGroupDescriptor(
  descriptor:
    | WPNavigationLink
    | WPPageGroupDescriptor
    | WPAsyncPageGroupDescriptor
    | WPPageDescriptor,
): descriptor is WPAsyncPageGroupDescriptor {
  return !!(
    "getPages" in descriptor && typeof descriptor.getPages === "function"
  );
}
