export enum WPCallToActionTypes {
  GoToFrontPage = "CallToActionGoToFrontPage",
  Login = "CallToActionLogin",
  GetTrialAccess = "CallToActionGetTrial",

  ReloadPage = "ReloadPAge",
  InternalLink = "InternalLink",
  ExternalLink = "ExternalLink",
}

type WPCallToActionLink = {
  text: string;
  linkUrl: string;
  type: WPCallToActionTypes.InternalLink | WPCallToActionTypes.ExternalLink;
};

type WPCallToActionButton = {
  text: string;
  type: Exclude<WPCallToActionTypes, WPCallToActionLink["type"]>;
};

export type WPCallToAction = WPCallToActionLink | WPCallToActionButton;
