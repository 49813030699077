import { User } from "../../models/User";
import { WPNavigationLink } from "../../models/WPNavigationLink";
import {
  WPPageDescriptor,
  WPPageGroupDescriptor,
} from "../../models/WPPageDescriptor";
import { isPageDescriptor } from "../../utils/guards/isPageDescriptor";
import { DAYS_TO_MS, HOURS_TO_MS } from "../constants/time";
import { createCache } from "../createCache";

import { ApiClientOptions } from "./typings/ApiClientOptions";
import { ApiRequestInterceptor } from "./typings/ApiRequestInterceptor";
import { fetchRequest } from "./utils/fetchRequest";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function makeFetchNavigation(
  requestInterceptor: ApiRequestInterceptor,
  options: ApiClientOptions,
) {
  const cache = createCache<
    (WPPageDescriptor | WPPageGroupDescriptor | WPNavigationLink)[]
  >({
    timeToLiveMs: 1 * HOURS_TO_MS,
    timeToEvictMs: 1 * DAYS_TO_MS,
    ...options,
  });

  return (user: User | undefined) => {
    const userType = user?.type ?? "guest";

    return cache(userType, async () => {
      const response = await fetchRequest<{
        items: (WPPageDescriptor | WPPageGroupDescriptor | WPNavigationLink)[];
      }>(
        requestInterceptor(
          `${process.env.WP_PORTAL_API}/navigation/user-role/${userType}`,
        ),
      );

      // the first item in the response is always the main page, overrule the
      // path in case SiteCore isn't aware of this
      const firstPage = response.items.find(isPageDescriptor);

      if (firstPage) {
        firstPage.path = "/";
      }

      return response.items;
    });
  };
}
