import React, { createContext, useContext, useEffect, useState } from "react";

import { User } from "../../../models/User";
import { ApiClient } from "../../../utils/api/_makeApiClient";

import { useApiClient } from "./useApiClient";

const UserContext = createContext<User | undefined | null>(null);

export function useUser(): User | undefined | null {
  return useContext(UserContext);
}

export function useAssertedUser(): User | undefined {
  const user = useContext(UserContext);

  if (user === null) {
    throw new Error("useAssertedUser(): SSO hasn't finished running yet");
  }

  return user;
}

export function UserProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const apiClient = useApiClient();
  const [user, setUser] = useState<User | undefined | null>(null);

  useEffect(() => {
    singleSignOn(apiClient).then((user) => setUser(user));
  }, [apiClient]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

async function singleSignOn(apiClient: ApiClient): Promise<User | undefined> {
  const loginResult =
    window._wp.ssr.loginConnectorToken === undefined
      ? await window.gProxy.fetchLoginConnectorToken(
          process.env.APP_ENV,
          "gu-unic",
        )
      : await window.gProxy.applyLoginConnectorToken(
          "gu-unic",
          window._wp.ssr.loginConnectorToken,
        );

  const userInfo = loginResult?.userInfo?.[0];

  if (!loginResult.loginConnectorToken || !userInfo) {
    return undefined;
  }

  return {
    loginConnectorToken: loginResult.loginConnectorToken,
    ...(await apiClient.fetchUser(userInfo.userId)),
  };
}
