import { cx } from "@linaria/core";
import { useRef } from "react";

import { useCssVariables } from "../../../hooks/effect/useCssVariables";

import * as styles from "./styles/Chevron";

type Props = {
  inversed?: boolean;
  vertical?: boolean;

  size?: number;
  color?: string;
  lineWidth?: number;

  className?: string;
};

export function Chevron({
  className,
  vertical,
  inversed,
  size,
  lineWidth,
  color,
}: Props): JSX.Element {
  const rootRef = useRef<HTMLElement>(null);

  useCssVariables(rootRef, {
    [styles.chevronProps.size]: size,
    [styles.chevronProps.lineWidth]: lineWidth,
    [styles.chevronProps.lineColor]: color,
  });

  return (
    <figure
      ref={rootRef}
      role="presentation"
      className={cx(
        className,
        styles.chevronCls,
        inversed && "inversed",
        vertical && "vertical",
      )}
    >
      <span
        className={cx(
          vertical ? styles.verticalLineCls : styles.horitzontalLineCls,
          "upper",
        )}
      />
      <span
        className={cx(
          vertical ? styles.verticalLineCls : styles.horitzontalLineCls,
          "lower",
        )}
      />
    </figure>
  );
}
