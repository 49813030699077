import { css } from "@linaria/core";

import { colorPropMixin } from "../../../../styles/mixins/colorProp";
import { fontMixin } from "../../../../styles/mixins/fontMixin";
import { outlineMixin } from "../../../../styles/mixins/outline";
import { anchorReset } from "../../../../styles/resets";
import { colorProps, proximaNovaProps } from "../../../../styles/variables";

export const linkCls = css`
  ${anchorReset};

  --underline-height: 3rem;
  --line-height: 2.4rem;

  /** setup spacing and sizing of underline indicator */
  display: block;
  padding: calc((var(--underline-height) - var(--line-height)) / 2) 0;

  ${fontMixin(proximaNovaProps, {
    weight: "regular",
    size: "1.6rem",
  })}
  line-height: var(--line-height);

  /** interaction styling */
  ${outlineMixin({
    targetSelector: "[data-wp-outline]",
    offset: { x: "1rem", y: "0.6rem" },
  })};

  &::before {
    content: "";

    position: absolute;
    top: -0.5rem;
    bottom: -0.5rem;
    right: -3rem;
    left: -99rem;
    z-index: -1;

    background: ${colorPropMixin(colorProps.grey_50)};

    opacity: 0;
    transition: opacity 0.1s linear;
  }

  &:hover::before {
    opacity: 1;
  }

  &[aria-current="page"] {
    font-weight: var(${proximaNovaProps.medium});
  }

  &[aria-current="page"],
  &.disabled {
    cursor: default;

    &::before {
      display: none;
    }
  }

  & [data-wp-outline] {
    display: inline-block;
  }
`;
