import { cx } from "@linaria/core";
import React, { useLayoutEffect, useRef } from "react";

import * as styles from "./styles/UnderlineActiveElement";

type Props = {
  className?: string;
  activeSelector: string;
  vertical?: boolean;
  color?: string;
  children: React.ReactNode;
};

export function UnderlineActiveElement({
  className,
  activeSelector,
  vertical = false,
  color,
  children,
}: Props): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  useUnderlineProps(ref, { activeSelector, vertical });

  return (
    <div
      ref={ref}
      className={cx(
        className,
        styles.wrapperCls,
        !vertical && styles.horizontalCls,
        vertical && styles.verticalCls,
      )}
      style={{
        // @ts-expect-error: React typings (wrongly) doesn't support custom
        // properties (CSS variables) in the style property
        [styles.underlineColorProp]: color,
      }}
    >
      {children}
    </div>
  );
}

function useUnderlineProps(
  ref: React.RefObject<HTMLDivElement>,
  {
    activeSelector,
    vertical,
  }: {
    activeSelector: string;
    vertical: boolean;
  },
): void {
  useLayoutEffect(() => {
    const container = ref.current;

    if (!container) {
      return;
    }

    let updateClassNameTimeout: number | undefined;

    const updateCssProps = () => {
      window.clearTimeout(updateClassNameTimeout);

      const activeElem = container.querySelector<HTMLElement>(activeSelector);

      if (!activeElem) {
        // if no element is active any longer, then simply collapse the size of
        // the element and update class names
        container.style.setProperty(styles.underlineSizeProp, "0px");

        // force re-flow to run transition correctly before toggling visible
        // state
        container.offsetWidth;
        container.classList.remove(styles.visibleCls);

        return;
      }

      // apply size and offset based on whether the underline is being rendered
      // horizontally or vertically
      const elemBox = activeElem.getBoundingClientRect();
      const containerBox = container.getBoundingClientRect();
      const scale = containerBox.width / container.offsetWidth;

      if (!vertical) {
        container.style.setProperty(
          styles.underlineSizeProp,
          `${elemBox.width * scale}px`,
        );
        container.style.setProperty(
          styles.underlineOffsetProp,
          `${
            (elemBox.left - (containerBox.left - container.scrollLeft)) * scale
          }px`,
        );
      } else {
        container.style.setProperty(
          styles.underlineSizeProp,
          `${elemBox.height * scale}px`,
        );
        container.style.setProperty(
          styles.underlineOffsetProp,
          `${
            (elemBox.top - (containerBox.top - container.scrollTop)) * scale
          }px`,
        );
      }

      // force re-flow to run transition correctle before toggling visible
      // state
      container.offsetWidth;
      container.classList.add(styles.visibleCls);
    };

    const mutationObserver = new MutationObserver((records) => {
      if (records.find((record) => record.target === container)) {
        return;
      }

      updateCssProps();
    });

    const resizeObserver = new ResizeObserver(() => {
      updateCssProps();
    });

    mutationObserver.observe(container, {
      subtree: true,
      attributes: true,
    });
    resizeObserver.observe(container);

    updateCssProps();

    container.classList.add(styles.readyCls);

    return () => {
      mutationObserver.disconnect();
      resizeObserver.disconnect();
      window.clearTimeout(updateClassNameTimeout);
    };
  }, [ref, activeSelector, vertical]);
}
