import { useEffect } from "react";

import { AppPage } from "./usePageFromRouter";

const BASE_TITLE = document.title;

export function useDocumentTitle(currPage: AppPage | undefined): void {
  useEffect(() => {
    const pageTitle = currPage?.pageDescriptor?.title;
    const productTitle = currPage?.product?.title;
    const isFrontpage =
      !currPage?.product && currPage?.pageDescriptor?.path === "/";

    if (isFrontpage) {
      document.title = BASE_TITLE;
    } else if (productTitle && pageTitle) {
      document.title = `${pageTitle} | ${productTitle} | ${BASE_TITLE}`;
    } else if (pageTitle) {
      document.title = `${pageTitle} | ${BASE_TITLE}`;
    } else if (productTitle) {
      document.title = `${productTitle} | ${BASE_TITLE}`;
    } else {
      document.title = BASE_TITLE;
    }
  }, [
    currPage?.pageDescriptor?.path,
    currPage?.pageDescriptor?.title,
    currPage?.product,
  ]);
}
