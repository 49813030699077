import React from "react";

import { User } from "../../../models/User";
import {
  WPApiActions,
  WPApiBlock,
  WPContentBlock,
  WPContentBlockTypes,
} from "../../../models/WPContentBlock";
import { WPPageDescriptor } from "../../../models/WPPageDescriptor";
import { WPProductConfig } from "../../../models/WPProductConfig";
import { ApiClient } from "../../../utils/api/_makeApiClient";
import { warnOnce } from "../../../utils/logs/warnOnce";
import { TextBox } from "../../components/blocks/TextBox/TextBox";

import { fetchAllProducts } from "./ApiPage/fetchAllProducts";
import { fetchAvailableProducts } from "./ApiPage/fetchAvailableProducts";
import { fetchPracticeTests } from "./ApiPage/fetchPracticeTests";
import { fetchStudentActiveTests } from "./ApiPage/fetchStudentActiveTests";
import { fetchStudentCompletedTests } from "./ApiPage/fetchStudentCompletedTests";
import { fetchTeacherAvailableTests } from "./ApiPage/fetchTeacherAvailableTests";
import { fetchUnavailableProducts } from "./ApiPage/fetchUnavailableProducts";
import { fetchAccordionCollection } from "./ContentPage/fetchAccordionCollection";
import { fetchImageElement } from "./ContentPage/fetchImageElement";
import { fetchProductBanner } from "./ContentPage/fetchProductBanner";
import { fetchResourceCollection } from "./ContentPage/fetchResourceCollection";
import { fetchTextElement } from "./ContentPage/fetchTextElement";
import { fetchVideoElement } from "./ContentPage/fetchVideoElement";

export async function fetchPageContent(
  apiClient: ApiClient,
  user: User | undefined,
  product: WPProductConfig | undefined,
  isAuthorized: boolean,
  pageDescriptor: WPPageDescriptor | undefined,
): Promise<JSX.Element> {
  if (pageDescriptor === undefined) {
    return import("./NotFound/NotFound").then(({ NotFound }) => <NotFound />);
  }

  const contentItems = await apiClient.fetchPage(user, pageDescriptor);
  const contentElements = await Promise.all(
    contentItems.map(async (block, index) =>
      block ? (
        <React.Fragment key={index}>
          {await renderContentBlock(
            apiClient,
            user,
            product,
            isAuthorized,
            block,
          )}
        </React.Fragment>
      ) : null,
    ),
  );

  return <>{contentElements}</>;
}

export async function renderContentBlock(
  apiClient: ApiClient,
  user: User | undefined,
  product: WPProductConfig | undefined,
  isAuthorized: boolean,
  block: WPContentBlock,
): Promise<JSX.Element | null> {
  switch (block.type) {
    case WPContentBlockTypes.TEXTBOX:
      if (block.showWhenNoProductAccess && isAuthorized) {
        // hide text block if it's only meant to be displayed for users that
        // doesn't have product access
        return null;
      }

      return <TextBox model={block} product={product} />;

    case WPContentBlockTypes.TEXT_ELEMENT:
      return fetchTextElement(block);

    case WPContentBlockTypes.IMAGE_ELEMENT:
      return fetchImageElement(block);

    case WPContentBlockTypes.VIDEO_ELEMENT:
      return fetchVideoElement(block);

    case WPContentBlockTypes.PRODUCT_BANNER:
      return fetchProductBanner(block);

    case WPContentBlockTypes.RESOURCE_COLLECTION:
      return fetchResourceCollection(block);

    case WPContentBlockTypes.ACCORDION_COLLECTION:
      return fetchAccordionCollection(block, product, isAuthorized);

    case WPContentBlockTypes.API_CALL:
      return renderApiBlock(apiClient, user, product, block);
  }

  warnOnce(
    "fetchPageContent().renderContentBlock(): unknown block type '%s'",
    // @ts-expect-error The API may potentially return more types than we've
    // explicitlly typed. Hence we need this warning.
    block.type,
  );

  return null;
}

async function renderApiBlock(
  apiClient: ApiClient,
  user: User | undefined,
  product: WPProductConfig | undefined,
  block: WPApiBlock,
): Promise<JSX.Element | null> {
  switch (block.action) {
    case WPApiActions.ALL_PRODUCTS:
      return fetchAllProducts(block);

    case WPApiActions.STUDENT_AVAILABLE_PRODUCTS:
    case WPApiActions.TEACHER_AVAILABLE_PRODUCTS:
      return fetchAvailableProducts(apiClient, user, block);

    case WPApiActions.TEACHER_UNAVAILABLE_PRODUCTS:
      return fetchUnavailableProducts(apiClient, user, block);

    case WPApiActions.STUDENT_ACTIVE_TESTS:
      return fetchStudentActiveTests(apiClient, user, product, block);

    case WPApiActions.STUDENT_COMPLETED_TESTS:
      return fetchStudentCompletedTests(apiClient, user, product, block);

    case WPApiActions.TEACHER_AVAILABLE_TESTS:
      return fetchTeacherAvailableTests(apiClient, user, product, block);

    case WPApiActions.PRACTICE_TESTS:
      return fetchPracticeTests(apiClient, user, product, block);
  }

  warnOnce(
    "fetchPageContent().renderApiBlock(): unknown action '%s'",
    block.action,
  );

  return null;
}
