import {
  WPAsyncPageGroupDescriptor,
  WPPageDescriptor,
  WPPageGroupDescriptor,
} from "../../../../models/WPPageDescriptor";
import { isPageDescriptor } from "../../../../utils/guards/isPageDescriptor";
import { UnderlineActiveElement } from "../../../components/atoms/UnderlineActiveElement/UnderlineActiveElement";
import { colorPropMixin } from "../../../styles/mixins/colorProp";
import { colorProps } from "../../../styles/variables";

import { LinkGroup } from "./LinkGroup";
import { LinkItem } from "./LinkItem";
import * as styles from "./styles/LinkList";

type Props = {
  items: (
    | WPPageGroupDescriptor
    | WPPageDescriptor
    | WPAsyncPageGroupDescriptor
  )[];
};

export function LinkList({ items }: Props): JSX.Element {
  return (
    <div className={styles.stickyCls}>
      <UnderlineActiveElement
        vertical
        className={styles.activeUnderlineCls}
        activeSelector={`[aria-current="page"]`}
        color={colorPropMixin(colorProps.primary)}
      >
        <ul className={styles.listCls}>
          {items.map((it) => (
            <li key={it.path} className={styles.itemCls}>
              {isPageDescriptor(it) ? (
                <LinkItem item={it} />
              ) : (
                <LinkGroup item={it} Link={LinkItem} />
              )}
            </li>
          ))}
        </ul>
      </UnderlineActiveElement>
    </div>
  );
}
