import { sanitize } from "dompurify";
import parse, { DOMNode } from "html-react-parser";

export function parseHtml(
  html: string,
  replacer?: (node: DOMNode) => JSX.Element | undefined,
): string | JSX.Element | JSX.Element[] {
  return parse(
    sanitize(html, {
      ALLOWED_TAGS: [
        "address",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "hgroup",
        "section",
        "blockquote",
        "dd",
        "div",
        "dl",
        "dt",
        "figcaption",
        "figure",
        "hr",
        "li",
        "ol",
        "p",
        "pre",
        "ul",
        "a",
        "abbr",
        "b",
        "bdi",
        "bdo",
        "br",
        "cite",
        "code",
        "data",
        "dfn",
        "em",
        "i",
        "kbd",
        "mark",
        "q",
        "rb",
        "rp",
        "rt",
        "rtc",
        "ruby",
        "s",
        "samp",
        "small",
        "span",
        "strong",
        "sub",
        "sup",
        "time",
        "u",
        "var",
        "wbr",
        "caption",
        "col",
        "colgroup",
        "table",
        "tbody",
        "td",
        "tfoot",
        "th",
        "thead",
        "tr",
      ],
      ALLOWED_ATTR: ["href", "name", "target", "src", "style"],
    }),
    {
      trim: true,
      replace: replacer,
    },
  );
}
