import { cx } from "@linaria/core";

import * as styles from "./styles/svg";
import { normalizeStrokeWidth } from "./utils/normalizeStrokeWidth";

type Props = {
  className?: string;
  strokeWidth?: number;
  height?: string | number;
  color?: string;
};

export function UserIcon({
  className,
  height,
  strokeWidth = 1.65,
  color = "currentColor",
}: Props): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18.779 21.5"
      className={cx(styles.svgCls, className)}
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      height={height}
      strokeWidth={normalizeStrokeWidth(strokeWidth, 21.5)}
    >
      <g transform="translate(.75 .75)">
        <circle cx="4.279" cy="4.279" r="4.279" transform="translate(4.279)" />
        <path d="M8.557 8.974A8.6 8.6 0 0 0 0 17.531h0v2.468h17.279v-2.468a8.746 8.746 0 0 0-8.722-8.557Z" />
      </g>
    </svg>
  );
}
