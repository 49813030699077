import { User } from "../../models/User";
import { WPContentBlock } from "../../models/WPContentBlock";
import { WPPageDescriptor } from "../../models/WPPageDescriptor";
import { DAYS_TO_MS, HOURS_TO_MS } from "../constants/time";
import { createCache } from "../createCache";

import { ApiClientOptions } from "./typings/ApiClientOptions";
import { ApiRequestInterceptor } from "./typings/ApiRequestInterceptor";
import { fetchRequest } from "./utils/fetchRequest";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function makeFetchPage(
  requestInterceptor: ApiRequestInterceptor,
  options: ApiClientOptions,
) {
  const cache = createCache<(WPContentBlock | null)[]>({
    timeToLiveMs: 1 * HOURS_TO_MS,
    timeToEvictMs: 1 * DAYS_TO_MS,
    ...options,
  });

  return (user: User | undefined, pageDescriptor: WPPageDescriptor) => {
    const userType = user?.type ?? "guest";

    return cache(`${userType}:${pageDescriptor.id}`, async () => {
      const response = await fetchRequest<{
        contentItems: (WPContentBlock | null)[];
      }>(
        requestInterceptor(
          `${process.env.WP_PORTAL_API}/pages/${pageDescriptor.id}/user-role/${userType}`,
        ),
      );

      return response.contentItems;
    });
  };
}
