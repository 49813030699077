export const NOT_FOUND_ERROR = new Error("fetchRequest(): status code 404");

export async function fetchRequest<T>(request: Request): Promise<T> {
  const response = await fetch(request);

  if (response.status < 200 || response.status >= 300) {
    console.error(
      `fetchRequest(): request failed with status code ${response.status}`,
      await response.text(),
      request,
    );

    if (response.status === 404) {
      throw NOT_FOUND_ERROR;
    } else {
      throw new Error(`fetchRequest(): status code not in range 200-299`);
    }
  }

  return response.json();
}
