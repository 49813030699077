import { css } from "@linaria/core";

import {
  MOBILE_BREAKPOINT_PX,
  TABLET_BREAKPOINT_PX,
} from "../../../styles/constants";
import {
  breakpointDownMixin,
  breakpointUpMixin,
} from "../../../styles/mixins/breakpoints";
import { colorPropMixin } from "../../../styles/mixins/colorProp";
import { fontMixin } from "../../../styles/mixins/fontMixin";
import { headingReset } from "../../../styles/resets";
import { colorProps, proximaNovaProps } from "../../../styles/variables";
import * as subNavigationStyles from "../SubNavigation/styles/SubNavigation";

export const contentPaddingProp = "--content--padding-bottom";

export const contentCls = css`
  ${contentPaddingProp}: 6rem;

  padding-top: var(${contentPaddingProp});
  padding-bottom: var(${contentPaddingProp});

  &.withSubNavigation {
    padding-left: var(${subNavigationStyles.subNavigationWidthProp});
  }

  @media screen and (${breakpointDownMixin(MOBILE_BREAKPOINT_PX)}) {
    ${contentPaddingProp}: 4.5rem;
  }
`;

export const pageHeadingBoundaryCls = css`
  margin-bottom: -2.7rem;
`;

export const pageHeadingCls = css`
  ${headingReset};

  ${fontMixin(proximaNovaProps, {
    weight: "medium",
    size: "1.6rem",
  })};

  color: ${colorPropMixin(colorProps.grey_400)};
  text-transform: uppercase;

  @media screen and (${breakpointUpMixin(TABLET_BREAKPOINT_PX)}) {
    display: none;
  }
`;
