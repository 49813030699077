import { cx } from "@linaria/core";
import React, { useLayoutEffect } from "react";

import { AppPage } from "../hooks/usePageFromRouter";
import { ErrorBoundary } from "../pages/ErrorBoundary/ErrorBoundary";

import { ContentBoundary } from "./ContentBoundary";
import * as styles from "./styles/PageWrapper";

type Props = {
  className: string;
  page: AppPage;
  withSubNavigation: boolean;
};

export const PageWrapper = React.memo(function PageWrapper({
  className,
  page,
  withSubNavigation,
}: Props): JSX.Element {
  // scroll to top of page automatically when mounting a new one
  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  const pageHeadingDescriptor =
    page.parentPageDescriptor ?? page.pageDescriptor;

  return (
    <article
      className={cx(
        className,
        styles.contentCls,
        withSubNavigation && "withSubNavigation",
      )}
    >
      <ErrorBoundary>
        {page.product &&
          pageHeadingDescriptor &&
          pageHeadingDescriptor.path !== `${page.product.path}/` && (
            <ContentBoundary className={styles.pageHeadingBoundaryCls}>
              <h2 className={styles.pageHeadingCls}>
                {pageHeadingDescriptor?.title}
              </h2>
            </ContentBoundary>
          )}
        {page.content}
      </ErrorBoundary>
    </article>
  );
});
