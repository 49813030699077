import { css } from "@linaria/core";

import {
  MOBILE_BREAKPOINT_PX,
  TABLET_BREAKPOINT_PX,
} from "../../../../styles/constants";
import { breakpointDownMixin } from "../../../../styles/mixins/breakpoints";
import { outlineMixin } from "../../../../styles/mixins/outline";
import { buttonReset } from "../../../../styles/resets";
import {
  colorProps,
  easingProps,
  headerPaddingProp,
  proximaNovaProps,
} from "../../../../styles/variables";

export const headerHeightProp = "--header--height";

export const headerSlideDurationMs = 200;

export const headerCls = css`
  :global(:root) {
    ${headerHeightProp}: 6rem;
  }

  position: fixed;
  inset: 0 0 auto 0;
  z-index: 1001;

  background: rgb(var(${colorProps.bg}));
  box-shadow: 0 0 10px rgba(var(${colorProps.shadow}), 0.2);

  transform: translateY(-100%);
  opacity: 0;

  &-enter {
    &-active {
      opacity: 1;
      transform: translateY(0);

      transition:
        opacity ${headerSlideDurationMs}ms linear,
        transform ${headerSlideDurationMs}ms var(${easingProps.outCubic});
    }

    &-done {
      opacity: 1;
      transform: unset;
    }
  }
`;

export const containerCls = css`
  display: flex;
  align-items: center;

  height: 6rem;
  gap: 1rem;
  padding: 0 var(${headerPaddingProp});

  position: relative;
  z-index: 2;
`;

export const logoWrapperCls = css`
  display: flex;
  align-items: center;
  cursor: pointer;

  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;

  ${outlineMixin({ offset: "0.8rem" })};

  @media screen and (${breakpointDownMixin(TABLET_BREAKPOINT_PX)}) {
    font-size: 1.8rem;
    margin-top: 1px;
  }
`;

export const logoCls = css`
  flex-shrink: 0;

  height: 2.8rem;
  margin-right: 1.5rem;
`;

export const headingCls = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  margin: 0;
  font-size: 2rem;
  line-height: 2.1rem;
  font-family: var(${proximaNovaProps.fontFamily});
  font-weight: var(${proximaNovaProps.medium});
  text-transform: uppercase;

  @media screen and (${breakpointDownMixin(MOBILE_BREAKPOINT_PX)}) {
    /**
     * intentionally fixate size of the heading on mobile devices; we don't have
     * enough space to allow it to grow on smaller devices in case the user has
     * asked for zoomed text size.
     **/
    font-size: 18px;

    /**
     * unless min css calculation is supported, in that case based  the size of
     * the font directly on the available space, then allow dynamic downscaling
     * of the font to fit the user's device.
     */
    font-size: min(calc((100vw - 12.5rem) / 14), 1.8rem);
  }
`;

export const rightSectionCls = css`
  display: flex;
  height: 100%;
`;

export const userButtonCls = css`
  ${buttonReset};

  position: relative;
  z-index: 1;

  /**
   * extend the clickable area so that the user can easily interact with the
   * button without having to manage to click exactly inside the icon
   */
  display: flex;
  align-items: center;
  height: 100%;

  padding: 0 2rem;
  margin: 0 -2rem;

  /**
   * material design style hover with fade-out of inactive elements once user
   * moves the cursor on top of the navigation bar, and then highlight of the
   * specific entry once hovered
   */
  transition: opacity 0.1s linear;

  .${rightSectionCls}:hover & {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  & svg {
    height: 2rem;
  }

  ${outlineMixin({
    targetSelector: "& > span",
    borderRadius: "50%",
    offset: "1rem",
  })};
`;
