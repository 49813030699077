import { css } from "@linaria/core";

import { figureReset } from "../../../../styles/resets";
import { easingProps } from "../../../../styles/variables";

export const chevronProps = {
  lineColor: "--chevron--line-color",

  // Must be specified as a unitless value, since we use it to calculate square
  // roots! See https://stackoverflow.com/questions/16251482/calculating-square-roots-with-css
  // NB: Will be interpreted as px
  size: "--chevron-size",

  // the width of the chevron line should be defined in intervals of .5px to
  // ensure proper rendering across browsers
  lineWidth: "--chevron--line-width",
};

const chevronHeightProp = "--chevron--height";
const chevronWidthProp = "--chevron--width";

const sizeSelector = `var(${chevronProps.size}, 10)`;
const widthSelector = `var(${chevronWidthProp})`;
const heightSelector = `var(${chevronHeightProp})`;

const lineColorSelector = `var(${chevronProps.lineColor}, currentColor)`;
const lineWidthSelector = `var(${chevronProps.lineWidth}, 2px)`;

export const chevronCls = css`
  /* Calculate the square root with a precision of 10, which seems to be adequate */
  --toSquare: ((${sizeSelector} * ${sizeSelector}) * 2);
  --sqrt01: calc((var(--toSquare) + (var(--toSquare) / var(--toSquare))) / 2);
  --sqrt02: calc((var(--sqrt01) + (var(--toSquare) / var(--sqrt01))) / 2);
  --sqrt03: calc((var(--sqrt02) + (var(--toSquare) / var(--sqrt02))) / 2);
  --sqrt04: calc((var(--sqrt03) + (var(--toSquare) / var(--sqrt03))) / 2);
  --sqrt05: calc((var(--sqrt04) + (var(--toSquare) / var(--sqrt04))) / 2);
  --sqrt06: calc((var(--sqrt05) + (var(--toSquare) / var(--sqrt05))) / 2);
  --sqrt07: calc((var(--sqrt06) + (var(--toSquare) / var(--sqrt06))) / 2);
  --sqrt08: calc((var(--sqrt07) + (var(--toSquare) / var(--sqrt07))) / 2);
  --sqrt09: calc((var(--sqrt08) + (var(--toSquare) / var(--sqrt08))) / 2);
  --sqrt: calc(
    1px * calc((var(--sqrt09) + (var(--toSquare) / var(--sqrt09))) / 2)
  );

  /* Calculate height of the chevron, using the Pythagorean theorem */
  ${chevronHeightProp}: calc(var(--sqrt) + ${lineWidthSelector} / 2);
  ${chevronWidthProp}: calc(${heightSelector} / 2 + ${lineWidthSelector} / 2);

  ${figureReset};

  display: block;
  position: relative;
  color: inherit;

  height: ${heightSelector};
  width: ${widthSelector};

  &.vertical {
    width: ${heightSelector};
    height: ${widthSelector};
  }
`;

export const horitzontalLineCls = css`
  position: absolute;
  top: 50%;
  right: 0;

  width: calc(${sizeSelector} * 1px);
  height: calc(2 * ${lineWidthSelector});

  background-color: ${lineColorSelector};

  transform-origin: calc(100% - ${lineWidthSelector} / 2) 50%;
  transition: transform 150ms var(${easingProps.inOutCubic});

  &.upper {
    transform: translateY(-50%) rotate(45deg) scaleY(0.5)
      translateY(${lineWidthSelector});
    transform-origin: right center;
  }

  &.lower {
    transform: translateY(-50%) rotate(-45deg) scaleY(0.5)
      translateY(calc(-1 * ${lineWidthSelector}));
    transform-origin: right center;
  }

  /* translate lines -70.710% to align with the left edge of the container
   * (value based on the Pythagorean theorem) and rotate the direction of the
   * chevron by additional 90deg */
  .${chevronCls}.inversed &.upper {
    transform: translateY(-50%)
      translateX(calc((${lineWidthSelector} / 2 + 70.71%) * -1)) rotate(135deg)
      scaleY(0.5) translateY(calc(-1 * ${lineWidthSelector}));
  }

  .${chevronCls}.inversed &.lower {
    transform: translateY(-50%)
      translateX(calc((${lineWidthSelector} / 2 + 70.71%) * -1)) rotate(-135deg)
      scaleY(0.5) translateY(${lineWidthSelector});
  }
`;

export const verticalLineCls = css`
  position: absolute;
  left: 50%;
  bottom: 0;

  height: calc(${sizeSelector} * 1px);
  width: calc(2 * ${lineWidthSelector});

  background-color: ${lineColorSelector};

  transform-origin: 50% calc(100% - ${lineWidthSelector} / 2);
  transition: transform 150ms var(${easingProps.outCubic});

  &.upper {
    transform: translateX(-50%) rotate(45deg) scaleX(0.5)
      translateX(calc(-1 * ${lineWidthSelector}));
    transform-origin: center bottom;
  }

  &.lower {
    transform: translateX(-50%) rotate(-45deg) scaleX(0.5)
      translateX(calc(1 * ${lineWidthSelector}));
    transform-origin: center bottom;
  }

  /* translate lines -70.710% to align with the left edge of the container
   * (value based on the Pythagorean theorem) and rotate the direction of the
   * chevron by additional 90deg */
  .${chevronCls}.inversed &.upper {
    transform: translateX(-50%)
      translateY(calc((${lineWidthSelector} / 2 + 70.71%) * -1)) rotate(135deg)
      scaleX(0.5) translateX(calc(1 * ${lineWidthSelector}));
  }

  .${chevronCls}.inversed &.lower {
    transform: translateX(-50%)
      translateY(calc((${lineWidthSelector} / 2 + 70.71%) * -1)) rotate(-135deg)
      scaleX(0.5) translateX(calc(-1 * ${lineWidthSelector}));
  }
`;
