/**
 * create a wrapper around the given callback, making sure that it will run at
 * most once for every animation frame.
 */
export function throttleToAnimationFrame(callback: () => void): () => void {
  let isCallbackPending = false;

  return (): void => {
    if (isCallbackPending) {
      return;
    }

    isCallbackPending = true;

    requestAnimationFrame(() => {
      isCallbackPending = false;
      callback();
    });
  };
}
