import { User } from "../../../../models/User";
import { WPApiBlock } from "../../../../models/WPContentBlock";
import { WPProductTypes } from "../../../../models/WPProductConfig";
import { ApiClient } from "../../../../utils/api/_makeApiClient";

export async function fetchUnavailableProducts(
  apiClient: ApiClient,
  user: User | undefined,
  block: WPApiBlock,
): Promise<JSX.Element> {
  const { products } = window._wp.ssr;

  const [authorizations, { ProductList }] = await Promise.all([
    apiClient.isAuthorized(
      user,
      products.map((it) => it.isbn),
    ),
    import("../../../components/blocks/ProductList/ProductList"),
  ]);

  const availableProducts = products.filter(
    (it) => !authorizations[String(it.isbn)] && it.type !== WPProductTypes.LINK,
  );

  return <ProductList model={block} products={availableProducts} />;
}
